import ErrorImage from "assets/error.png";

function ErrorStep() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 sm:gap-4">
      <div className="flex flex-col justify-center">
        <h2 className="mb-8">
          Něco se nepodařilo, zkuste konfigurátor za chvíli znovu
        </h2>
        <h3>
          Mezitím se můžete podívat na to, co vás nejčastěji zajímá ohledně
          rekonstrukcí.
        </h3>
      </div>
      <img src={ErrorImage} alt="Error" />
    </div>
  );
}

export default ErrorStep;
