import { Ref } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ChevronRightWhite from "assets/icons/chevron-right-white.svg";
import ChevronRightBlue from "assets/icons/chevron-right-blue.svg";
import ChevronLeft from "assets/icons/chevron-left.svg";

type ButtonProps = {
  label: string;
  labelMobile?: string;
  type?: "primary" | "secondary" | "link";
  icon?: string;
  direction?: "left" | "right";
  className?: string;
} & (
  | {
      onClick: () => void;
      isSubmit?: boolean;
      buttonRef?: Ref<HTMLButtonElement>;
    }
  | {
      to: string;
      onClick?: () => void;
      target?: string;
      buttonRef?: Ref<HTMLAnchorElement>;
    }
);

function Button(props: ButtonProps) {
  // FIXME: shouldnt it be in css instead of html?
  const icon = (
    <img
      src={
        props.icon ??
        (props.direction === "left"
          ? ChevronLeft // TODO add ChevronLeftBlue
          : props.type === "link"
            ? ChevronRightBlue
            : ChevronRightWhite)
      }
      alt={props.label}
    />
  );

  const label = (
    <>
      <span className="hidden sm:flex gap-4">
        {props.direction === "left" && icon}
        {props.label}
        {props.direction !== "left" && icon}
      </span>
      <span className="flex sm:hidden gap-4">
        {props.direction === "left" && icon}
        {props.labelMobile !== undefined ? props.labelMobile : props.label}
        {props.direction !== "left" && icon}
      </span>
    </>
  );

  const isLink = "to" in props;
  const className = classNames(
    "btn",
    "flex-shrink",
    {
      "btn-primary": !props.type || props.type === "primary",
      "btn-secondary": props.type === "secondary",
      "btn-link": props.type === "link",
    },
    props.className,
  );

  return isLink ? (
    <Link
      className={className}
      to={props.to}
      target={props.target}
      onClick={props.onClick}
      ref={props.buttonRef}
    >
      {label}
    </Link>
  ) : (
    <button
      className={className}
      type={props.isSubmit ? "submit" : undefined}
      onClick={props.onClick}
      ref={props.buttonRef}
    >
      {label}
    </button>
  );
}

export default Button;
