import Block from "components/Block";
import Input from "components/form/Input";
import { useFormikContext } from "formik";
import { DimensionsAreaType, FormData } from "shared/types";
import { computeWindowsArea } from "shared/computations";
import { useContext } from "react";
import ConstantsContext from "contexts/constantsContext";
import { mockFormDataNoPartner } from "shared/form";
import round from "lodash/round";

function Step4() {
  const { values } = useFormikContext<FormData>();
  const constants = useContext(ConstantsContext);
  const {
    windowsSmall,
    windowsMedium,
    windowsLarge,
    doors: doorsValue,
  } = values;
  const dimensions: DimensionsAreaType | undefined = constants?.dimensions;
  const doors = doorsValue || mockFormDataNoPartner.doors;
  const doorsDimensions = dimensions ? doors * dimensions.doors : 0;
  const wallArea = computeWindowsArea(
    {
      windowsSmall,
      windowsMedium,
      windowsLarge,
      doors,
    },
    dimensions,
  );

  return (
    <>
      <h2>Teď se podíváme na okna</h2>
      <Block headline="Kolik jich máte?">
        <h5 className="mt-8">Malá okna</h5>
        <Input
          label={`Okna v koupelně nebo na toaletě. Počítáme s velikostí okna okolo 80x80 cm (${dimensions?.windowsSmall} m²).`}
          type="number"
          name="windowsSmall"
        />
        <h5 className="mt-10">Střední okna</h5>
        <Input
          label={`Běžná okna. Počítáme s velikostí okna okolo 120x150 cm (${dimensions?.windowsMedium} m²).`}
          type="number"
          name="windowsMedium"
        />
        <h5 className="mt-10">Velká okna</h5>
        <Input
          label={`Výjimečně velká okna, např. francouzská. Počítáme s velikostí okna okolo 150x300 cm (${dimensions?.windowsLarge} m²).`}
          type="number"
          name="windowsLarge"
        />
        <Input
          label="Celková plocha oken"
          disabled
          value={round(wallArea - doorsDimensions, 2)}
          unit="m²"
          className="mt-10"
        />
      </Block>
    </>
  );
}

export default Step4;
