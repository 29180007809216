import Question from "assets/icons/question.svg";
import classNames from "classnames";
import "./Tooltip.scss";

interface TooltipProps {
  text: string;
  className?: string | string[];
}

function Tooltip(props: TooltipProps) {
  return (
    <img
      src={Question}
      alt=" ? "
      className={classNames("max-w-none", "inline", props.className)}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={props.text}
      data-tooltip-variant="light"
    />
  );
}

export default Tooltip;
