import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Logo from "assets/logo.png";
import "Layout.scss";
import { isAnalyticsLoaded, trackPageView } from "helpers/analytics";
import { getLink, RouteEnum } from "helpers/routes";

function Layout() {
  const { pathname } = useLocation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isAnalyticsLoaded()) {
      trackPageView(pathname);
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="Layout--header">
        <div>
          <Link to={getLink(RouteEnum.HomePage)}>
            <img src={Logo} alt="ČSOB logo" width="84" height="56" />
          </Link>
        </div>
      </div>
      <div className="Layout--content">
        <Outlet />
      </div>

      <div className="Layout--footer">
        <div>
          <span>Copyright © {currentYear} ČSOB</span>
          <a href="https://www.csob.cz/portal/">www.csob.cz</a>
          <a href="https://www.csob.cz/portal/podminky-pouzivani">
            Cookies a podmínky používání
          </a>
          <a href="https://www.csob.cz/lide/pujcky/popis-produktu-a-vzorove-priklady-pro-kate">
            Reprezentativní příklad
          </a>
        </div>
      </div>
      <ReactTooltip id="my-tooltip" />
    </>
  );
}

export default Layout;
