import { StepEnum } from "helpers/steps";

export const configuratorTips: { [key in StepEnum]: null | string | string[] } =
  {
    [StepEnum.Step1]: [
      "Začal bych od podlahy, teda plánování.",
      "S přesným zadáním a odborně zpracovanou studií ušetříte na rekonstrukci nejvíc.",
    ],
    [StepEnum.Step2]: [
      "Nejdražší položkou při zateplování jsou řemeslné práce, ne samotný izolant.",
      "Na tloušťce izolace nešetřete, jinak budete v zimě modří jako já.",
    ],
    [StepEnum.Step3]: [
      "Šikovně zvolený přesah střechy váš dům příjemně ochladí během léta.",
      "A zateplením střechy snížíte náklady na vytápění až o 15 %. Kéž by to tak šlo i v mém terárku.",
    ],
    [StepEnum.Step4]: [
      "Stará okna a dveře jsou většinou nejslabším článkem obálky budovy a uniká jimi velké množství tepla –⁠ až 40%. V létě zase kvalitní okna přispějí k chladnějšímu domu.",
      "Navíc vás více skel ochrání před nechtěným hlukem. A klid je fajn.",
    ],
    [StepEnum.Step5]: [
      "Při renovaci vytápění můžete vyměnit jen zdroj tepla. Někdy je ale potřeba minimálně úprava, nebo výměna celé otopné soustavy.",
      "Proto nový způsob vytápění zkonzultujte s odborníkem. Ví o tom víc, než my dva dohromady.",
    ],
    [StepEnum.GrantType]: null,
    [StepEnum.Savings]:
      "Největší podíl spotřeby energie padne na vytápění domu, obzvlášť když z něj teplo uniká. Nejúspornější je zateplený dům s účinným zdrojem vytápění.",
    [StepEnum.Results]: [
      "Komplexní rekonstrukce dokáže snížit spotřebu energie o 80 - 90 %.",
      "Dílčí renovace (výměna oken, zateplení obálky budovy) uspoří pouze okolo 40 % nákladů na energie. Často také vede k dalším problémům v domě např. plísním.",
      "Proto se opravdu vyplatí renovaci navrhnout s odborníky.",
    ],
    [StepEnum.Financing]: [
      "S financováním si teď nelamte hlavu, poradí vám s ním naši specialisté.",
      "Nejdříve budete potřebovat připravit projekt, pak požádáte stavební firmy o nabídky. Z nabídek pak určíte cenu rekonstrukce a výši úvěru.",
    ],
    [StepEnum.Contact]:
      "Na e-mail vám pošleme shrnutí navržené rekonstrukce. Poté vám zavolá náš specialista a naplánujete společně další kroky. ",
    [StepEnum.ThankYou]: null,
  };
