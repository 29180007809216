import Block from "components/Block";
import Select from "components/form/Select";
import Input from "components/form/Input";
import { useFormikContext } from "formik";
import {
  HeatingSourceEnum,
  FormData,
  HotWaterSourceEnum,
  MeasureKeys,
  MeasureSetting,
} from "shared/types";
import Radio from "components/form/Radio";
import { yearLabel } from "helpers/yearLabel";
import { useEffect } from "react";

const HouseMembersOptions = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8 a více",
};

export const HeatingSourceOptions = {
  [HeatingSourceEnum.GasBoiler]: "plynový kotel",
  [HeatingSourceEnum.GasCondensingBoiler]: "plynový kondenzační kotel",
  [HeatingSourceEnum.ElectricBoiler]:
    "elektrokotel nebo elektrické akumulační topení",
  [HeatingSourceEnum.CoalBoiler]: "kotel na uhlí",
  [HeatingSourceEnum.BiomassBoiler]: "kotel na biomasu",
  // [HeatingSourceEnum.WaterHeater]: "bojler na ohřev teplé vody",
  [HeatingSourceEnum.HeatPump]: "tepelné čerpadlo",
  [HeatingSourceEnum.Fireplace]: "krbová kamna",
  [HeatingSourceEnum.Other]: "mám jiný zdroj",
};

export const HotWaterSourceOptions = {
  [HotWaterSourceEnum.GasCondensingBoiler]: "plynový kondenzační kotel",
  [HotWaterSourceEnum.WaterHeater]: "průtokový ohřívač vody",
  [HotWaterSourceEnum.ElectricBoiler]: "elektrický bojler",
  [HotWaterSourceEnum.HeatPump]: "tepelné čerpadlo",
};

interface Step5Props {
  setting: MeasureSetting;
  toggleSetting: (measure: MeasureKeys) => void;
}

function Step5(props: Step5Props) {
  const { values } = useFormikContext<FormData>();
  const { fvInstalled } = values;

  useEffect(() => {
    if (fvInstalled === props.setting.fv) {
      props.toggleSetting("fv");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fvInstalled]);

  return (
    <>
      <h2>Vytápěním spotřebováváte nejvíce energie</h2>
      <Block
        headline="Kolik osob ve vašem domě dlouhodobě bydlí?"
        copy="Čím více lidí, tím větší spotřeba tepla"
      >
        <Select options={HouseMembersOptions} name="houseMembers" />
      </Block>
      <Block headline="Jaký je váš hlavní zdroj tepla?">
        <Select
          options={HeatingSourceOptions}
          label="Vyberte zdroj, kterým nejvíce topíte"
          placeholder="typ zdroje"
          name="heatingSource"
        />
        {values.heatingSource &&
          values.heatingSource !== HeatingSourceEnum.Other && (
            <Input
              label="Kolik let máte tento zdroj?"
              name="heatingSourceAge"
              type="number"
              unit={yearLabel(values.heatingSourceAge)}
            />
          )}
      </Block>
      <Block headline="Přitápíte krbem?">
        <Radio name="hasFireplace" inline />
      </Block>
      <Block headline="Čím ohříváte vodu?">
        <Select
          options={HotWaterSourceOptions}
          label="Ohřev vody"
          placeholder="typ zdroje"
          name="hotWaterSource"
        />
        {values.hotWaterSource && (
          <Input
            label="Kolik let máte tento zdroj?"
            name="hotWaterSourceAge"
            type="number"
            unit={yearLabel(values.hotWaterSourceAge)}
          />
        )}
      </Block>
      <Block headline="Máte nainstalovanou fotovoltaickou elektrárnu?">
        <Radio name="fvInstalled" inline />
      </Block>
    </>
  );
}

export default Step5;
