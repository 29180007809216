function Disclaimer() {
  return (
    <div className="mt-8 mb-8 sm:mb-0 px-8 sm:px-4 sm:text-sm text-base-content/40">
      Výpočty jsou orientační a vychází z dat Centra pasivního domu. Výše dotace
      je odvozena z dotačního programu Nová zelená úsporám. Skupina ČSOB
      neprovádí ani nezprostředkovává energeticky úsporná řešení, není garantem
      poskytnutí a případné výše dotace.
    </div>
  );
}

export default Disclaimer;
