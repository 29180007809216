import { StepEnum } from "helpers/steps";

export const configuratorSteps: { [key in StepEnum]: string | undefined } = {
  [StepEnum.Step1]: "typ domu",
  [StepEnum.Step2]: "rozměry domu",
  [StepEnum.Step3]: "střecha",
  [StepEnum.Step4]: "okna",
  [StepEnum.Step5]: "vytápění a ohřev vody",
  [StepEnum.GrantType]: "dotační titul",
  [StepEnum.Savings]: "měsíční úspory",
  [StepEnum.Results]: "navržená rekonstrukce",
  [StepEnum.Financing]: "financování",
  [StepEnum.Contact]: "souhrn a kontaktní údaje",
  [StepEnum.ThankYou]: undefined,
};

export const isSubmittingStep = (step: StepEnum) =>
  step === StepEnum.Results - 1 || step === StepEnum.ThankYou - 1;
