import * as Yup from "yup";
import {
  FuelType,
  FormData,
  GrantTypeEnum,
  HeatingSourceEnum,
  HotWaterSourceEnum,
  PrepaymentsBalance,
  RoofTypeEnum,
} from "shared/types";
import { StepEnum } from "helpers/steps";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useAppStore } from "stores/appStore";
import { FormikErrors } from "formik";
import { getPartnerSalutation } from "shared/textations";
import { heatingSourceFuelMap, hotWaterSourceFuelMap } from "shared/measure";

export const getGrantTypeInitialValues = (partner: boolean = false) => {
  const storeState = useAppStore.getState();
  const inputData = partner ? {} : storeState.configuratorData;
  return {
    permanentResidence: inputData?.permanentResidence,
    economicallyActive: inputData?.economicallyActive,
    children: inputData?.children,
  };
};

export const DEFAULT_PHONE_NUMBER = "+420";

export const getInitialValues = (partner: boolean = false): FormData => {
  const storeState = useAppStore.getState();
  const inputData = partner ? {} : storeState.configuratorData;
  const defaultValues = {
    // Step 1
    houseAge: undefined,
    // Step 2
    area: undefined,
    floors: undefined,
    basement: undefined,
    heatedBasement: undefined,
    // Step 3
    roofType: undefined,
    attic: undefined,
    // Step 4
    windowsSmall: undefined,
    windowsMedium: undefined,
    windowsLarge: undefined,
    // Step 5
    houseMembers: undefined,
    heatingSource: undefined,
    heatingSourceAge: undefined,
    hasFireplace: undefined,
    hotWaterSource: undefined,
    hotWaterSourceAge: undefined,
    fvInstalled: undefined,
    // Savings
    expenses: undefined,
    prepaymentsFrequencyGas: undefined,
    prepaymentsFrequencyElectricity: undefined,
    prepaymentsGasCost: undefined,
    prepaymentsElectricityCost: undefined,
    prepaymentsBalanceGas: undefined,
    prepaymentsBalanceGasCost: undefined,
    prepaymentsBalanceElectricity: undefined,
    prepaymentsBalanceElectricityCost: undefined,
    prepaymentsCoalCost: undefined,
    prepaymentsBiomassCost: undefined,

    // GrantType
    ...getGrantTypeInitialValues(partner),
    // Results
    grantType: GrantTypeEnum.NZUS,
    // Financing
    loanLength: 20,
    ownSavings: 0,
    // Contact
    email: undefined,
    ...(partner
      ? {
          partner: true,
          partnerName: undefined,
          partnerTel: DEFAULT_PHONE_NUMBER,
          partnerEmail: undefined,
          partnerType: getPartnerSalutation(),
        }
      : {
          partner: false,
          tel: DEFAULT_PHONE_NUMBER,
          agreement: false,
        }),
  };

  return Object.assign(defaultValues, inputData);
};

export const grantTypeValidationSchema = (skipChildren: boolean) =>
  Yup.object().shape({
    permanentResidence: Yup.boolean().required(
      "Bez tohoto údaje se neobejdeme. Vyberte prosím zda plánujete mít v domě trvalé bydliště.",
    ),
    economicallyActive: Yup.boolean().required(
      "Bez tohoto údaje se neobejdeme. Vyberte prosím zda jste ekonomicky aktivní.",
    ),
    children: skipChildren
      ? Yup.number()
      : Yup.number().when(["permanentResidence", "economicallyActive"], {
          is: (permanentResidence: boolean, economicallyActive: boolean) =>
            permanentResidence && economicallyActive,
          then: (schema) =>
            schema.required(
              "Bez tohoto údaje se neobejdeme. Vyplňte prosím počet dětí.",
            ),
        }),
  });

export const validationSchema = (step: number, partner: boolean) => {
  if (step === StepEnum.Step1)
    return Yup.object().shape({
      houseAge: Yup.string().required(
        "Bez tohoto údaje se neobejdeme. Vyplňte prosím rok výstavby.",
      ),
    });

  if (step === StepEnum.Step2)
    return Yup.object().shape({
      area: Yup.number()
        .min(0, "Plocha musí být kladné číslo.")
        .required("Bez tohoto údaje se neobejdeme. Vyplňte prosím plochu."),
      floors: Yup.number().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím počet podlaží.",
      ),
      basement: Yup.boolean().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím zda máte sklep.",
      ),
      heatedBasement: Yup.boolean().when("basement", {
        is: true,
        then: (schema) =>
          schema.required(
            "Bez tohoto údaje se neobejdeme. Vyberte prosím zda je sklep vytápěný.",
          ),
      }),
    });

  if (step === StepEnum.Step3)
    return Yup.object().shape({
      roofType: Yup.string().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím typ střechy.",
      ),
      attic: Yup.boolean().when("roofType", {
        is: (type: RoofTypeEnum) => type !== RoofTypeEnum.Flat,
        then: (schema) =>
          schema.required(
            "Bez tohoto údaje se neobejdeme. Vyberte prosím zda máte podkroví.",
          ),
      }),
    });

  if (step === StepEnum.Step4)
    return Yup.object().shape({
      windowsSmall: Yup.number()
        .min(0, "Počet oken musí být kladné číslo.")
        .required(
          "Bez tohoto údaje se neobejdeme. Vyplňte prosím počet malých oken.",
        ),
      windowsMedium: Yup.number()
        .min(0, "Počet oken musí být kladné číslo.")
        .required(
          "Bez tohoto údaje se neobejdeme. Vyplňte prosím počet středně velkých oken.",
        ),
      windowsLarge: Yup.number()
        .min(0, "Počet oken musí být kladné číslo.")
        .required(
          "Bez tohoto údaje se neobejdeme. Vyplňte prosím počet velkých oken.",
        ),
    });

  if (step === StepEnum.Step5)
    return Yup.object().shape({
      houseMembers: Yup.number().required(
        "Bez tohoto údaje se neobejdeme. Vyplňte prosím počet členů domácnosti.",
      ),
      heatingSource: Yup.string().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím zdroj vytápění.",
      ),
      heatingSourceAge: Yup.number().when("heatingSource", {
        is: (system: HeatingSourceEnum) => system !== HeatingSourceEnum.Other,
        then: (schema) =>
          schema
            .min(0, "Počet let musí být kladné číslo.")
            .required(
              "Bez tohoto údaje se neobejdeme. Vyplňte prosím před kolika lety jste zdroj vytápění pořídili.",
            ),
      }),
      hasFireplace: Yup.boolean().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím zda máte krb nebo krbová kamna.",
      ),
      hotWaterSource: Yup.string().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím zdroj ohřevu vody.",
      ),
      hotWaterSourceAge: Yup.number().when("hotWaterSource", {
        is: (system: HotWaterSourceEnum) => !!system,
        then: (schema) =>
          schema
            .min(0, "Počet let musí být kladné číslo.")
            .required(
              "Bez tohoto údaje se neobejdeme. Vyplňte prosím před kolika lety jste zdroj ohřeevu vody pořídili.",
            ),
      }),
      fvInstalled: Yup.string().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím zda máte fotovoltaickou elektrárnu.",
      ),
    });

  if (step === StepEnum.Savings)
    return Yup.object().shape({
      expenses: Yup.boolean().required(
        "Bez tohoto údaje se neobejdeme. Vyberte prosím jednu z variant",
      ),
      //  Gas
      prepaymentsFrequencyGas: Yup.string().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsGas,
          then: (schema) =>
            schema.required(
              "Bez tohoto údaje se neobejdeme. Vyberte prosím interval platby záloh.",
            ),
        },
      ),
      prepaymentsGasCost: Yup.number().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsGas,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za plyn.",
              ),
        },
      ),
      prepaymentsBalanceGas: Yup.string().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsGas,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za plyn.",
              ),
        },
      ),
      prepaymentsBalanceGasCost: Yup.number().when(
        [
          "expenses",
          "heatingSource",
          "hotWaterSource",
          "prepaymentsBalanceGas",
        ],
        {
          is: shouldValidateSavingsGas,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za plyn.",
              ),
        },
      ),
      // Elekctricity
      prepaymentsFrequencyElectricity: Yup.string().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsEl,
          then: (schema) =>
            schema.required(
              "Bez tohoto údaje se neobejdeme. Vyberte prosím interval platby záloh.",
            ),
        },
      ),
      prepaymentsElectricityCost: Yup.number().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsEl,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za elektřinu.",
              ),
        },
      ),
      prepaymentsBalanceElectricity: Yup.string().when(
        ["expenses", "heatingSource", "hotWaterSource"],
        {
          is: shouldValidateSavingsEl,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za elektřinu.",
              ),
        },
      ),
      prepaymentsBalanceElectricityCost: Yup.number().when(
        [
          "expenses",
          "heatingSource",
          "hotWaterSource",
          "prepaymentsBalanceElectricity",
        ],
        {
          is: shouldValidateSavingsEl,
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za elektřinu.",
              ),
        },
      ),
      // Coal
      prepaymentsCoalCost: Yup.number().when(["expenses", "heatingSource"], {
        is: (expenses: boolean, source: HeatingSourceEnum) =>
          expenses === true && heatingSourceFuelMap[source] === FuelType.COAL,
        then: (schema) =>
          schema
            .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
            .required(
              "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za uhlí.",
            ),
      }),
      // Biomass
      prepaymentsBiomassCost: Yup.number().when(
        ["expenses", "heatingSource", "hasFireplace"],
        {
          is: (
            expenses: boolean,
            source: HeatingSourceEnum,
            hasFireplace: boolean,
          ) =>
            expenses === true &&
            (heatingSourceFuelMap[source] === FuelType.BIOMASS || hasFireplace),
          then: (schema) =>
            schema
              .min(1, "Hodnota zálohy musí být kladná částka větší než 0")
              .required(
                "Bez tohoto údaje se neobejdeme. Vyplňte prosím útratu za biomasu.",
              ),
        },
      ),
    });
  if (step === StepEnum.GrantType) return grantTypeValidationSchema(false);

  if (step === StepEnum.Financing)
    return Yup.object().shape({
      ownSavings: Yup.number().min(0, "Vaše úspory musí být kladné číslo."),
    });

  if (step === StepEnum.Contact)
    if (partner)
      return Yup.object().shape({
        email: Yup.string()
          .email("Nemáte tam překlep? Zadejte prosím platný email.")
          .required("Bez tohoto údaje se neobejdeme. Vyplňte prosím email."),
        partnerName: Yup.string().required(
          `Bez tohoto údaje se neobejdeme. Vyplňte prosím jméno ${getPartnerSalutation(
            false,
          )}.`,
        ),
        partnerTel: Yup.string()
          .test(
            "is-phone-number",
            `Nemáte tam překlep? Zadejte prosím platné telefonní číslo ${getPartnerSalutation(
              false,
            )} včetně předvolby.`,
            (value) => !!value && isValidPhoneNumber(value),
          )
          .required(
            "Bez tohoto údaje se neobejdeme. Vyplňte prosím telefonní číslo.",
          ),
        partnerEmail: Yup.string()
          .email(
            `Nemáte tam překlep? Zadejte prosím platný email ${getPartnerSalutation(
              false,
            )}.`,
          )
          .required(
            `Bez tohoto údaje se neobejdeme. Vyplňte prosím email ${getPartnerSalutation(
              false,
            )}.`,
          ),
      });
    else
      return Yup.object().shape({
        email: Yup.string()
          .email("Nemáte tam překlep? Zadejte prosím platný email.")
          .required("Bez tohoto údaje se neobejdeme. Vyplňte prosím email."),
        tel: Yup.string()
          .test(
            "is-phone-number",
            "Nemáte tam překlep? Zadejte prosím platné telefonní číslo včetně předvolby.",
            (value) => !!value && isValidPhoneNumber(value),
          )
          .required(
            "Bez tohoto údaje se neobejdeme. Vyplňte prosím telefonní číslo.",
          ),
        agreement: Yup.boolean().oneOf(
          [true],
          "Bez vašeho souhlasu se zpracováním osobních údajů se neobejdeme.",
        ),
      });
};

export const leadFormValidationSchema = () =>
  Yup.object().shape({
    tel: Yup.string()
      .test(
        "is-phone-number",
        "Nemáte tam překlep? Zadejte prosím platné telefonní číslo včetně předvolby.",
        (value) => !!value && isValidPhoneNumber(value),
      )
      .required(
        "Bez tohoto údaje se neobejdeme. Vyplňte prosím telefonní číslo.",
      ),
    agreement: Yup.boolean().oneOf(
      [true],
      "Bez vašeho souhlasu se zpracováním osobních údajů se neobejdeme.",
    ),
  });

export const getTouchedFromErrors = <T>(errors: FormikErrors<T>) =>
  Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {});

function shouldValidateSavingsGas(
  expenses: boolean,
  heatingSource: HeatingSourceEnum,
  hotWaterSource: HotWaterSourceEnum,
  balance: PrepaymentsBalance,
): boolean {
  return shouldValidateSavingsField(
    FuelType.GAS,
    expenses,
    heatingSource,
    hotWaterSource,
    balance,
  );
}

function shouldValidateSavingsEl(
  expenses: boolean,
  heatingSource: HeatingSourceEnum,
  hotWaterSource: HotWaterSourceEnum,
  balance: PrepaymentsBalance,
): boolean {
  return shouldValidateSavingsField(
    FuelType.ELECTRICITY,
    expenses,
    heatingSource,
    hotWaterSource,
    balance,
  );
}

function shouldValidateSavingsField(
  source: FuelType,
  expenses: boolean,
  heatingSource: HeatingSourceEnum,
  hotWaterSource: HotWaterSourceEnum,
  balance: PrepaymentsBalance,
): boolean {
  if (expenses === true) {
    if (
      balance === PrepaymentsBalance.DONTKNOW ||
      balance === PrepaymentsBalance.MATCHING
    ) {
      return false;
    }
    return (
      heatingSourceFuelMap[heatingSource] === FuelType[source] ||
      hotWaterSourceFuelMap[hotWaterSource] === FuelType[source]
    );
  }
  return false;
}