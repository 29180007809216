import Page from "components/layout/Page";
import ErrorImage from "assets/error.png";
import Button from "components/Button";
import { getLink, RouteEnum } from "helpers/routes";

function Error404Page() {
  return (
    <Page title="Stránka nenalezena">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 sm:gap-4">
        <div className="flex flex-col justify-center text-center lg:text-left">
          <h2 className="mb-8">Jejda, stránka nenalezena!</h2>
          <h3 className="mb-10">
            Bohužel, takovou stránku neznáme. Zkontrolujte, jestli jste zadali
            správnou adresu.
          </h3>
          <div className="hidden lg:block">
            <Button
              label="Přejít na hlavní stránku"
              type="secondary"
              to={getLink(RouteEnum.HomePage)}
            />
          </div>
        </div>
        <img src={ErrorImage} alt="Error" />
        <Button
          className="lg:hidden"
          label="Přejít na hlavní stránku"
          type="secondary"
          to={getLink(RouteEnum.HomePage)}
        />
      </div>
    </Page>
  );
}

export default Error404Page;
