import { getPartnerParam } from "helpers/routes";

export const grantTips = {
  1: [
    "O dotace můžete požádat už před začátkem stavebních úprav.",
    "S dotacemi vám bezplatně poradí kvalifikovaní odborníci v konzultačním středisku EKIS (Energetické konzultační a informační středisko).",
  ],
  2: [
    "Komplexní rekonstrukce dokáže snížit spotřebu energie o 80 - 90 %.",
    "Dílčí renovace (výměna oken, zateplení obálky budovy) uspoří pouze okolo 40 % nákladů na energie. Často také vede k dalším problémům v domě např. plísním.",
    "Proto se opravdu vyplatí renovaci navrhnout s odborníky.",
  ],
  3: `Vypadá to jako hodně kroků, ale není se třeba bát. Naši ${
    getPartnerParam() === "csob" ? "specialisté" : "obchodní zástupci"
  } vám rádi pomohou se v tom vyznat.`,
};

export const howToApplyTips = {
  1: "Aktuální a úplné informace včetně seznamu potřebných dokumentů vždy najdete na webu novazelenausporam.cz.",
  2: `S podáním žádosti o dotaci vám pomůže náš ${
    getPartnerParam() === "csob" ? "specialista" : "obchodní zástupce"
  } ze sítě ČSOB Stavební. Stavební firmu vám pomůžeme vybrat v sekci Vyberu si partnerskou stavební firmu.`,
};

export const getPartnerSalutation = (nominativ?: boolean) => {
  switch (getPartnerParam()) {
    case "csob":
      return nominativ === false ? "specialisty" : "specialista";
    case "true":
      return nominativ === false ? "obchodního zástupce" : "obchodní zástupce";
    default:
      return "";
  }
};