import classNames from "classnames";
import ChevronDown from "assets/icons/chevron-down-white.svg";
import { useState } from "react";
import "components/layout/Steps.scss";

interface StepsProps<T extends number> {
  actualStep: T;
  steps: { [key in T]: string | undefined };
  mobileTipVisible?: boolean;
}

function Steps<T extends number>(props: StepsProps<T>) {
  const [expanded, setExpanded] = useState(false);

  const steps = Object.values(props.steps).filter((step) => step !== undefined);
  const smStep = (props.actualStep - (props.mobileTipVisible ? 1 : 0)) as T;
  const lastStep = steps.length;

  return (
    <div
      className={classNames(
        "collapse",
        "lg:collapse-open",
        "lg:pt-4",
        "border-b-2",
        "lg:border-none",
        "border-primary-focus",
        {
          "collapse-open": expanded,
        },
      )}
    >
      <div
        className="collapse-title lg:hidden p-4"
        onClick={() => setExpanded((e) => !e)}
      >
        <progress
          className="progress progress-primary mt-2 mb-4"
          value={smStep}
          max={lastStep}
        />
        <div className="flex justify-between font-bold">
          <span>
            {smStep}/{lastStep}
          </span>
          <span>{props.steps[smStep]}</span>
          <img
            src={ChevronDown}
            alt="zobrazit více"
            className="collapse-icon"
          />
        </div>
      </div>
      <div className="collapse-content">
        <ul className="steps steps-vertical my-4">
          {steps.map((label, key) => (
            <li
              className={classNames("step", {
                "lg:step-primary": key < props.actualStep,
                "step-primary": key < smStep,
              })}
              data-content={key < props.actualStep - 1 ? "check" : ""}
              key={key}
            >
              {label as string}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Steps;
