import classNames from "classnames";
import "pages/configurator/components/GrantTypeCard.scss";

interface GrantTypeCardProps {
  type: "static" | "selectable";
  label: string;
  attributes: string[];
  conditions?: string[];
  available?: boolean;
  selected?: boolean;
  onSelect?: () => void;
}

function GrantTypeCard(props: GrantTypeCardProps) {
  return (
    <div
      className={classNames("grant-type-card", {
        unknown: props.available === undefined,
        disabled: props.available === false,
        selectable: props.type === "selectable",
        selected: props.selected,
      })}
      onClick={props.type === "selectable" ? props.onSelect : undefined}
    >
      <h4 className="heading">{props.label}</h4>
      <div className="body">
        <ul>
          {props.attributes.map((attribute, index) => (
            <li key={index} className="attribute">
              {attribute}
            </li>
          ))}
          {props.type === "selectable" &&
            props.conditions?.map((attribute, index) => (
              <li key={index} className="condition">
                {attribute}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default GrantTypeCard;
