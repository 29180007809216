// import classNames from "classnames";
import "./Modal.scss";
import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import CloseIcon from "assets/icons/close-cross.svg";

interface ModalProps {
  children: ReactNode;
  isOpened: boolean;
  onClose: () => void;
}

function Modal(props: ModalProps) {
  const refDialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    refDialog.current?.showModal();
  });

  function onClickOutside(event: React.MouseEvent<HTMLDialogElement>) {
    if (event.target === refDialog.current) {
      refDialog.current?.close();
    }
  }

  return createPortal(
    <dialog
      className="Modal"
      ref={refDialog}
      onClose={props.onClose}
      onClick={onClickOutside}
    >
      <form method="dialog" className="Modal--close">
        <button>
          <img src={CloseIcon} alt="X" />
        </button>
      </form>
      {props.children}
    </dialog>,
    document.body,
  );
}

export default Modal;
