import Tooltip from "components/Tooltip";
import classNames from "classnames";
import "./Switcher.scss";

interface SwitcherProps {
  label?: string;
  checked?: boolean;
  onChange?: () => void;
  tooltip?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

function Switcher(props: SwitcherProps) {
  return (
    <div className={classNames("form-control", "switcher", props.className)}>
      <label className="cursor-pointer label justify-start py-1">
        <input
          type="checkbox"
          className="toggle order-last sm:order-none ml-auto sm:ml-0 sm:mr-2.5"
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
        {props.label && (
          <span className="label-text mr-2.5">{props.label}</span>
        )}
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </label>
    </div>
  );
}

export default Switcher;
