import classNames from "classnames";
import Loading from "components/layout/Loading";
import { ReactNode, useState } from "react";
import LeftBar from "components/layout/LeftBar";
import Navigation from "components/layout/Navigation";

interface AppLayoutProps<T extends number> {
  children: ReactNode;
  showLeftBar?: boolean;
  actualStep: T;
  steps: { [key in T]: string | undefined };
  tips?: { [key in T]: string | string[] | null };
  tipHeadline: string;
  nextStepLabel?: string;
  nextStepLabelMobile?: string;
  goToNextStep?: () => boolean | Promise<boolean>; // returns true if form is valid
  previousStepLabel?: string;
  previousStepLabelMobile?: string;
  previousStepIcon?: "chevron-left" | "cycle";
  goToPreviousStep?: () => void;
  isSubmittingStep?: boolean;
  loading?: boolean;
  loadingDelayed?: boolean;
  loadingText?: string;
}

function PageLayout<T extends number>(props: AppLayoutProps<T>) {
  const [mobileTipVisible, setMobileTipVisible] = useState(false);
  const goToNextStep = async (mobile: boolean) => {
    const validationSuccessful = (await props.goToNextStep?.()) ?? true;
    if (validationSuccessful) {
      if (mobile && props.tips?.[props.actualStep]) {
        // Shows tip for the last visible step – see <Tip /> for more info
        setMobileTipVisible(true);
      }
      window.scrollTo(0, 0);
    }
  };

  const goToPreviousStep = () => {
    props.goToPreviousStep?.();
    setMobileTipVisible(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="grid grid-cols-10 bg-white">
      {(props.showLeftBar ?? true) && (
        <LeftBar
          actualStep={props.actualStep}
          steps={props.steps}
          tips={props.tips}
          tipHeadline={props.tipHeadline}
          mobileTipVisible={mobileTipVisible}
          toggleMobileTipVisible={() => setMobileTipVisible((v) => !v)}
          goToPreviousStep={goToPreviousStep}
        />
      )}

      <div
        className={classNames(
          "flex flex-col justify-between relative col-span-full",
          {
            "lg:col-span-7": props.showLeftBar ?? true,
            "hidden lg:flex": (props.showLeftBar ?? true) && mobileTipVisible,
          },
        )}
      >
        <div className="px-4 sm:px-8 py-6 sm:py-16">{props.children}</div>
        <Navigation
          nextLabel={props.nextStepLabel}
          nextLabelMobile={props.nextStepLabelMobile}
          backLabel={props.previousStepLabel}
          backLabelMobile={props.previousStepLabelMobile}
          nextClick={goToNextStep}
          isNextSubmit={props.isSubmittingStep}
          backClick={goToPreviousStep}
          backIcon={props.previousStepIcon}
        />
        <Loading
          delayed={props.loadingDelayed}
          loadingText={props.loadingText}
        />
      </div>
    </div>
  );
}

export default PageLayout;
