import PageLayout from "components/layout/PageLayout";
import Page from "components/layout/Page";
import { useState } from "react";
import { useAppStore } from "stores/appStore";
import { useNavigate } from "react-router-dom";
import Step1 from "pages/how-to-apply/components/Step1";
import Step2 from "pages/how-to-apply/components/Step2";
import Disclaimer from "components/Disclaimer";
import { trackStep } from "helpers/analytics";
import { getLink, RouteEnum } from "helpers/routes";
import { howToApplyTips } from "shared/textations";

const steps = { 1: "potřebné dokumenty", 2: "jak podat žádost" };

function HowToApplyPage() {
  const [actualStep, setActualStep] = useState(1);
  const inputDataStore = useAppStore();
  const navigate = useNavigate();

  const goToNextStep = () => {
    if (actualStep < 2) {
      setActualStep(actualStep + 1);
      trackStep(actualStep + 1);
    } else {
      inputDataStore.completeStep(6);
      navigate(getLink(RouteEnum.HomePage));
    }

    return true;
  };

  const goToPreviousStep = () => {
    if (actualStep > 1) {
      setActualStep(actualStep - 1);
      trackStep(actualStep - 1);
    } else {
      navigate(getLink(RouteEnum.HomePage));
    }
  };

  return (
    <Page title="Jak a kde požádat">
      <PageLayout
        actualStep={actualStep}
        steps={steps}
        tips={howToApplyTips}
        tipHeadline="Tip k dotacím"
        nextStepLabel={actualStep === 1 ? "Pokračovat" : "Zpět na rozcestník"}
        goToNextStep={goToNextStep}
        previousStepLabel="Zpět"
        goToPreviousStep={goToPreviousStep}
      >
        {actualStep === 1 && <Step1 />}
        {actualStep === 2 && <Step2 />}
      </PageLayout>
      <Disclaimer />
    </Page>
  );
}

export default HowToApplyPage;
