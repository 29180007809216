import Clock from "assets/icons/chevron-clock.svg";
import ClockCounter from "assets/icons/chevron-clock-counter.svg";
// import Check from "assets/icons/check-circle-green.svg";
// import Minus from "assets/icons/minus-circle-black.svg";
import { formatNumber } from "shared/number";
import { GrantTypeEnum } from "shared/types";

const dataGrantDesktop = (totalGrant?: { [key in GrantTypeEnum]: number }) => ({
  headCells: [
    { cell: "NZÚ Oprav dům po babičce", preCell: "Vyplatí se" },
    { cell: "NZÚ Standard" },
  ],
  rows: [
    {
      highlighted: true,
      cells: [
        { cell: "Kdy dotaci dostanu" },
        {
          cell: (
            <>
              <img src={ClockCounter} alt="" />
              Před rekonstrukcí
            </>
          ),
        },
        {
          cell: (
            <>
              <img src={Clock} alt="" />
              Po rekonstrukci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: totalGrant ? "Celková výše dotace" : "Maximální výše dotace",
          tooltip: totalGrant
            ? "Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám."
            : undefined,
        },
        {
          cell: (
            <>
              {totalGrant
                ? `${formatNumber(totalGrant[GrantTypeEnum.Babicka])} Kč`
                : "Až 1 milion Kč na důkladné zateplení + více na další opatření"}
            </>
          ),
        },
        {
          cell: (
            <>
              {totalGrant
                ? `${formatNumber(totalGrant[GrantTypeEnum.NZUS])} Kč`
                : "Až 50% výdajů"}
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: "Rozsah rekonstrukce",
          tooltip:
            "Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
        { cell: "Pouze komplexní rekonstrukce" },
        { cell: "Libovolný rozsah rekonstrukce" },
      ],
    },
    {
      cells: [
        {
          cell: "Podmínka trvalého bydliště",
          tooltip:
            "V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ",
        },
        { cell: "Ano" },
        { cell: "Ne" },
      ],
    },
    {
      cells: [
        {
          cell: "Podmínka komplexního zateplení",
          tooltip:
            "Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
        { cell: "Ano" },
        { cell: "Ne" },
      ],
    },
    {
      cells: [
        {
          cell: "Kombinační bonus",
          tooltip:
            "Bonus ve výši 10 000 Kč za vybrané kombinace opatření – např. zateplení + fotovoltaika = 10 000 Kč, zateplení + fotovoltaika + dešťovka = 20 000 Kč",
        },
        { cell: "10 000 Kč za každou kombinaci" },
        { cell: "10 000 Kč za každou kombinaci" },
      ],
    },
    {
      cells: [
        {
          cell: "Bonus pro znevýhodněné kraje",
          tooltip:
            "Bonus 10 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.",
        },
        { cell: "Navýšení dotace o 10 %" },
        { cell: "Navýšení dotace o 10 %" },
      ],
    },
    {
      cells: [
        {
          cell: "Bonus za nezaopatřené dítě",
          tooltip:
            "Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.",
        },
        { cell: "50 000 Kč za každé dítě" },
        { cell: "Ne" },
      ],
    },
  ],
});
const dataGrantMobile = (totalGrant?: { [key in GrantTypeEnum]: number }) => ({
  switch: true,
  addEmptyHeadCell: false,
  headCells: [
    { cell: "NZÚ Oprav dům po babičce", preCell: "Vyplatí se" },
    { cell: "NZÚ Standard" },
  ],
  rows: [
    {
      cells: [
        {
          cell: (
            <>
              <img src={ClockCounter} alt="" />
              Dotace <b>už před</b> rekonstrukcí
            </>
          ),
        },
        {
          cell: (
            <>
              <img src={Clock} alt="" />
              Dotace <b>až po</b> rekonstrukci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          tooltip: totalGrant
            ? "Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám."
            : undefined,
          cell: totalGrant ? (
            <>
              Celková výše dotace{" "}
              <b>{formatNumber(totalGrant[GrantTypeEnum.Babicka])} Kč</b>
            </>
          ) : (
            "Až 1 milion Kč na důkladné zateplení + více na další opatření"
          ),
        },
        {
          status: true,
          tooltip: totalGrant
            ? "Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám."
            : undefined,
          cell: totalGrant ? (
            <>
              Celková výše dotace{" "}
              <b>{formatNumber(totalGrant[GrantTypeEnum.NZUS])} Kč</b>
            </>
          ) : (
            "Až 50% výdajů"
          ),
        },
      ],
    },
    {
      cells: [
        {
          status: false,
          cell: (
            <>
              Pouze <b>komplexní</b> rekonstrukce
            </>
          ),
          tooltip:
            "Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
        {
          status: true,
          cell: (
            <>
              <b>Libovolný</b> rozsah rekonstrukce
            </>
          ),
          tooltip:
            "Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
      ],
    },
    {
      cells: [
        {
          status: false,
          cell: (
            <>
              <b>S podmínkou</b> trvalého bydliště
            </>
          ),
          tooltip:
            "V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ",
        },
        {
          status: true,
          cell: (
            <>
              <b>Bez podmínky</b> trvalého bydliště
            </>
          ),
          tooltip:
            "V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ",
        },
      ],
    },
    {
      cells: [
        {
          status: false,
          cell: (
            <>
              <b>S podmínkou</b> komplexního zateplení
            </>
          ),
          tooltip:
            "Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
        {
          status: true,
          cell: (
            <>
              <b>Bez podmínky</b> komplexního zateplení
            </>
          ),
          tooltip:
            "Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.",
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>Kombinační bonus 10 000 Kč</b> za každou kombinace
            </>
          ),
          tooltip:
            "Bonus ve výši 10 000 Kč za vybrané kombinace opatření – např. zateplení + fotovoltaika = 10 000 Kč, zateplení + fotovoltaika + dešťovka = 20 000 Kč",
        },
        {
          status: true,
          cell: (
            <>
              <b>Kombinační bonus 10 000 Kč</b> za každou kombinace
            </>
          ),
          tooltip:
            "Bonus ve výši 10 000 Kč za vybrané kombinace opatření – např. zateplení + fotovoltaika = 10 000 Kč, zateplení + fotovoltaika + dešťovka = 20 000 Kč",
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>Navýšení dotace o 10%</b> pro znevýhodněné kraje
            </>
          ),
          tooltip:
            "Bonus 10 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.",
        },
        {
          status: true,
          cell: (
            <>
              <b>Navýšení dotace o 10%</b> pro znevýhodněné kraje
            </>
          ),
          tooltip:
            "Bonus 10 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.",
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>50 000 Kč</b> za každé dítě
            </>
          ),
          tooltip:
            "Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.",
        },
        {
          status: false,
          cell: (
            <>
              <b>Bez bonusu</b> za neopatřené dítě
            </>
          ),
          tooltip:
            "Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.",
        },
      ],
    },
  ],
});
export const dataProgress = {
  addEmptyHeadCell: false,
  headCells: [{ cell: "NZÚ Oprav dům po babičce" }, { cell: "NZÚ Standard" }],
  rows: [
    {
      cells: [
        {
          cell: (
            <>
              <span data-line />
              <i data-stepid>1</i>
              návrh energetických úspor
            </>
          ),
          tooltip:
            "Návrh energetických úspor obsahuje odhad cen jednotlivých opatření a dotací na jednotlivá opatření.",
        },
        {
          cell: (
            <>
              <span data-line />
              <i data-stepid>1</i>
              návrh energetických úspor
            </>
          ),
          tooltip:
            "Návrh energetických úspor obsahuje odhad cen jednotlivých opatření a dotací na jednotlivá opatření.",
        },
      ],
    },
    {
      cells: [
        { cell: null },
        {
          cell: (
            <>
              <i data-stepid data-blue>
                2
              </i>
              žádost o úvěr
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>2</i>
              odborný posudek
            </>
          ),
          tooltip:
            "Odborný posudek v oblasti novostavby a zateplení se zpravidla skládá ze dvou částí – projektové dokumentace a energetického hodnocení budovy, u ostatních opatření je jednodušší. Posudek musí být zpracován osobou s příslušnou autorizací či oprávněním.",
        },
        {
          cell: (
            <>
              <i data-stepid>3</i>
              odborný posudek
            </>
          ),
          tooltip:
            "Odborný posudek v oblasti novostavby a zateplení se zpravidla skládá ze dvou částí – projektové dokumentace a energetického hodnocení budovy, u ostatních opatření je jednodušší. Posudek musí být zpracován osobou s příslušnou autorizací či oprávněním.",
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>3</i>
              žádost o dotaci
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>4</i>
              žádost o dotaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid data-red>
                4
              </i>
              dostanu dotaci
            </>
          ),
        },
        {
          cell: null,
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid data-blue>
                5
              </i>
              žádost o úvěr
            </>
          ),
        },
        { cell: null },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>6</i>
              rekonstruuji
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>5</i>
              rekonstruuji
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>7</i>
              dokládám realizaci
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>6</i>
              dokládám realizaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        { cell: null },
        {
          cell: (
            <>
              <i data-stepid data-red>
                7
              </i>
              dostanu dotaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>8</i>
              mám hotovo
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>8</i>
              mám hotovo
            </>
          ),
        },
      ],
    },
  ],
};

export function getGrantTableData(
  mobile: boolean,
  totalGrant?: { [key in GrantTypeEnum]: number },
) {
  return (mobile ? dataGrantMobile : dataGrantDesktop)(totalGrant);
}
