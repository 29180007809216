import { FormData, GrantTypeEnum } from "shared/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface GrantData {
  totalGrant: { [key in GrantTypeEnum]: number };
}

interface AppState {
  configuratorData: FormData | null;
  setConfiguratorData: (data: FormData) => void;
  updateConfiguratorData: (data: FormData) => void;
  grantData: GrantData | null;
  setGrantData: (grantData: GrantData) => void;
  completedSteps: { [key: number]: boolean };
  completeStep: (step: number) => void;
  clearStore: () => void;
}

export const defaultState: {
  configuratorData: FormData | null;
  grantData: GrantData | null;
  completedSteps: { [key: number]: boolean };
} = {
  configuratorData: null,
  grantData: null,
  completedSteps: {
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  },
};

export const useAppStore = create(
  devtools(
    persist<AppState>(
      (set, _) => ({
        ...defaultState,
        setConfiguratorData: (configuratorData) =>
          set((_) => ({ configuratorData })),
        updateConfiguratorData: (configuratorData) =>
          set((state) => ({
            configuratorData: {
              ...state.configuratorData,
              ...configuratorData,
            },
          })),
        setGrantData: (grantData) => set((_) => ({ grantData })),
        completeStep: (step) => {
          set((state) => {
            const completedSteps = { ...state.completedSteps };
            completedSteps[step] = true;
            return { completedSteps };
          });
        },
        clearStore: () => {
          set(() => defaultState);
        },
      }),
      {
        name: "configurator-data",
      },
    ),
  ),
);

export function useShouldShowLeadForm() {
  return useAppStore((state) => {
    const partner = !!new URLSearchParams(window.location.search).get(
      "partner",
    );
    const tel =
      state.configuratorData?.partner === false
        ? state.configuratorData.tel
        : undefined;
    return !partner && tel === undefined;
  });
}
