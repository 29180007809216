import Tooltip from "components/Tooltip";
import "./Table.scss";
import { ReactNode, useState } from "react";
import classNames from "classnames";

interface TableProps {
  data: {
    switch?: boolean;
    addEmptyHeadCell?: boolean;
    headCells: TableCellProps[];
    rows: TableRowProps[];
  };
  className?: string;
  children?: ReactNode;
}

function Table(props: TableProps) {
  const { data, children } = props;
  const [switchValue, setSwitchValue] = useState<number>(1);
  const renderContentSwitch = data.switch && data.headCells.length > 1;

  return (
    <>
      {renderContentSwitch && (
        <TableSwitch
          data={data.headCells}
          currentValue={switchValue}
          handleOnClick={setSwitchValue}
        />
      )}
      <table
        className={classNames("Table", props.className)}
        data-switch={renderContentSwitch ? switchValue : null}
      >
        {children}
        {!renderContentSwitch && (
          <TableHead
            cells={data.headCells}
            addFirstCell={data.addEmptyHeadCell}
          />
        )}
        <TableBody rows={data.rows} />
      </table>
    </>
  );
}

interface TableHeadProps {
  cells: TableCellProps[];
  addFirstCell?: boolean;
}
const TableHead = (props: TableHeadProps) => {
  return (
    <thead className="Table--head">
      <tr>
        {props.addFirstCell !== false && <th> </th>}

        {props.cells.map((cell, i: number) => (
          <th className="Table__cell" key={`thead-cell-${i}`}>
            {cell.preCell && <span data-precell={true}>{cell.preCell}</span>}
            <span>{cell.cell}</span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface TableBodyProps {
  rows: {
    cells: TableCellProps[];
  }[];
}
const TableBody = (props: TableBodyProps) => {
  return (
    <tbody className="Table--body">
      {props.rows.map((cells, i: number) => (
        <TableRow {...cells} key={`tbody-row-${i}`} />
      ))}
    </tbody>
  );
};

export interface TableRowProps {
  highlighted?: boolean;
  cells: TableCellProps[];
}
const TableRow = (props: TableRowProps) => {
  const { cells } = props;

  return (
    <tr
      className={classNames("Table__row", {
        "trow-highlighted": props.highlighted,
      })}
    >
      {cells.map((cell, i: number) => (
        <TableCell {...cell} key={`trow-cell-${i}`} />
      ))}
    </tr>
  );
};

export interface TableCellProps {
  cell: string | ReactNode | null;
  preCell?: string | ReactNode;
  tooltip?: string;
  status?: boolean;
}
const TableCell = (props: TableCellProps) => {
  return (
    <td
      className="Table__cell"
      data-status={props.status !== undefined ? props.status : null}
      data-emptycell={props.cell === null ? true : null}
    >
      <span>{props.cell}</span>
      {props.tooltip && <Tooltip text={props.tooltip} />}
    </td>
  );
};

interface TableSwitchProps {
  data: TableCellProps[];
  currentValue: number;
  handleOnClick: (value: number) => void;
}
function TableSwitch(props: TableSwitchProps) {
  const activeValue = props.currentValue || 1;
  return (
    <div className="Table--switch">
      {props.data.map((item, i: number) => (
        <span
          className={classNames("button", {
            "button--active": i + 1 === activeValue,
          })}
          onClick={() => props.handleOnClick(i + 1)}
          key={`tswitch-button-${i}`}
        >
          {item.cell}
        </span>
      ))}
    </div>
  );
}

export default Table;
