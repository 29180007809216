import {
  FuelType,
  HeatingSourceEnum,
  HotWaterSourceEnum,
  MeasureKeys,
  MeasureSetting,
} from "./types";

export const measureAdditionalInfo: {
  [key in MeasureKeys]: {
    label: string;
    fourthFallLabel: string;
    tooltip?: string;
  };
} = {
  wallInsulation: {
    label: "Zateplení stěn",
    fourthFallLabel: "zateplení stěn",
    tooltip: "Zateplení stěn o tloušťce 20 - 28 cm.",
  },
  roofInsulation: {
    label: "Zateplení střechy",
    fourthFallLabel: "zateplení střechy",
    tooltip: "Nová střecha se zateplením o tloušťce 30 – 40 cm.",
  },
  windows: {
    label: "Výměna oken",
    fourthFallLabel: "výměnu oken",
    tooltip: "Výměna za kvalitní okna s trojskly s předsazenou montáží.",
  },
  floorInsulation: {
    label: "Zateplení podlahy",
    fourthFallLabel: "zateplení podlahy",
    tooltip: "Zateplení podlahy o tloušťce 15 - 30 cm.",
  },
  ventilation: {
    label: "Řízené větrání s rekuperací",
    fourthFallLabel: "řízené větrání s rekuperací",
  },
  heatPump: {
    label: "Tepelné čerpadlo",
    fourthFallLabel: "tepelné čerpadlo",
    tooltip:
      "V uvedené ceně počítáme i s novou otopnou soustavou. Stará otopná soustava má vysoké tepelné ztráty.",
  },
  gasCondensingBoiler: {
    label: "Plynový kondenzační kotel",
    fourthFallLabel: "plynový kondenzační kotel",
    tooltip:
      "V uvedené ceně počítáme i s novou otopnou soustavou. Stará otopná soustava má vysoké tepelné ztráty.",
  },
  fv: {
    label: "Fotovoltaická elektrárna",
    fourthFallLabel: "fotovoltaickou elektrárnu",
    tooltip:
      "Výkon 6 kWp a bez baterie. Takový výkon stačí pro běžnou domácnost." +
      "Kilowatt-peak (kWp) je jednotka špičkového výkonu fotovoltaické elektrárny. Jedná se tedy o maximální výkon ideálně umístěného panelu. Elektrárna o výkonu 6 kWp vyrobí ročně 5 700 – 6 300 kWh.",
  },
  documentation: {
    label: "Projektová dokumentace",
    fourthFallLabel: "projektovou dokumentaci",
    tooltip:
      "Obecně se cena projektová dokumentace pohybuje okolo " +
      "4 % z celkové ceny projektu.",
  },
};

export const heatingSourceFuelMap: Record<HeatingSourceEnum, FuelType> = {
  // Heating
  [HeatingSourceEnum.GasBoiler]: FuelType.GAS,
  [HeatingSourceEnum.GasCondensingBoiler]: FuelType.GAS,
  [HeatingSourceEnum.CoalBoiler]: FuelType.COAL,
  [HeatingSourceEnum.BiomassBoiler]: FuelType.BIOMASS,
  [HeatingSourceEnum.HeatPump]: FuelType.ELECTRICITY,
  [HeatingSourceEnum.ElectricBoiler]: FuelType.ELECTRICITY,
  [HeatingSourceEnum.WaterHeater]: FuelType.ELECTRICITY,
  [HeatingSourceEnum.Fireplace]: FuelType.BIOMASS,
  [HeatingSourceEnum.Other]: FuelType.OTHER,
};
export const hotWaterSourceFuelMap = {
  // Hot Water heating
  [HotWaterSourceEnum.GasCondensingBoiler]: FuelType.GAS,
  [HotWaterSourceEnum.WaterHeater]: FuelType.ELECTRICITY,
  [HotWaterSourceEnum.ElectricBoiler]: FuelType.ELECTRICITY,
  [HotWaterSourceEnum.HeatPump]: FuelType.ELECTRICITY,
};

export const measureSavingsSetting: MeasureSetting = {
  wallInsulation: true,
  roofInsulation: true,
  windows: true,
  floorInsulation: true,
  ventilation: true,
  heatPump: true,
  gasCondensingBoiler: false,
  fv: true,
  documentation: true,
};