import { StepEnum } from "helpers/steps";
import { AxiosError } from "axios";

export const nextStepLabel = (step: StepEnum, error?: AxiosError) => {
  if (error || step === StepEnum.ThankYou) {
    return "Zpět na hlavní stránku";
  }

  if (step === StepEnum.Results - 1) {
    return "Ukázat řešení";
  }

  if (step === StepEnum.Financing - 1) {
    return "Pokračovat na financování";
  }

  if (step === StepEnum.Contact - 1) {
    return "Poslat výsledky";
  }

  if (step === StepEnum.ThankYou - 1) {
    return "Odeslat";
  }

  return "Pokračovat";
};

export const nextStepLabelMobile = (step: StepEnum, error?: AxiosError) => {
  if (error || step === StepEnum.ThankYou) {
    return "Zpět na hlavní stránku";
  }

  if (step === StepEnum.Results - 1) {
    return "Ukázat řešení";
  }

  if (step === StepEnum.Contact - 1) {
    return "Poslat souhrn na e-mail";
  }

  if (step === StepEnum.ThankYou - 1) {
    return "Odeslat";
  }

  return "Pokračovat";
};

export const previousStepLabel = (step: StepEnum, error?: AxiosError) =>
  step === StepEnum.ThankYou || error ? "Vyplnit konfigurátor znovu" : "Zpět";

export const previousStepLabelMobile = (step: StepEnum, error?: AxiosError) =>
  step === StepEnum.Step1
    ? undefined
    : step === StepEnum.ThankYou || error
      ? "Vyplnit znovu"
      : "Zpět";

export const previousStepIcon = (step: StepEnum, error?: AxiosError) =>
  step === StepEnum.Step1
    ? undefined
    : step === StepEnum.ThankYou || error
      ? "cycle"
      : "chevron-left";
