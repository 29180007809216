import Block from "components/Block";
import GrantTypeCard from "pages/configurator/components/GrantTypeCard";
import { useFormikContext } from "formik";
import { FormData, GrantTypeEnum } from "shared/types";
import { getGrantTypes } from "shared/grantTypes";

interface GrantTypeSwitcherProps {
  onGrantTypeSelect: (grantType: GrantTypeEnum) => void;
}

function GrantTypeSwitcher(props: GrantTypeSwitcherProps) {
  const { values } = useFormikContext<FormData>();
  const grantTypes = getGrantTypes(values);

  return values.permanentResidence && values.economicallyActive ? (
    <Block>
      <h5 className="mb-4">Doporučená dotace</h5>
      <div className="grid sm:grid-cols-2 gap-4">
        {Object.entries(grantTypes).map(([key, value]) => (
          <GrantTypeCard
            key={key}
            type="selectable"
            {...value}
            selected={key === values.grantType}
            onSelect={() => props.onGrantTypeSelect(key as GrantTypeEnum)}
          />
        ))}
      </div>
    </Block>
  ) : null;
}

export default GrantTypeSwitcher;
