import Step1 from "assets/steps/step1.svg";
import Step2Img from "assets/steps/step2.svg";
import Step3 from "assets/steps/step3.svg";
import Step4 from "assets/steps/step4.svg";
import Step5 from "assets/steps/step5.svg";
import Block from "components/Block";
import IconChat from "assets/icons/chat.svg";
import LeadForm from "components/form/LeadForm";
import { Reasons } from "shared/reasons";
import { useShouldShowLeadForm } from "stores/appStore";
import { useRef } from "react";
import { getPartnerSalutation } from "shared/textations";

function Step2() {
  // Save the value of shouldShowLeadForm to a ref so that the success message can be still shown
  const shouldShowLeadForm = useRef(useShouldShowLeadForm());

  return (
    <>
      <h2 className="mb-6">Jak probíhá podání žádosti o dotaci</h2>
      <h4 className="mb-8 lg:mb-14">
        O dotaci žádáte online na webu Státního fondu životního prostředí
        (SFŽP). Postup žádosti o všechny varianty dotací NZÚ probíhá stejně.
      </h4>
      <Block className="space-y-4">
        <div className="flex gap-6 items-center">
          <img src={Step1} alt="Krok 1" />
          <div>
            Máte <strong>bankovní identitu</strong> nebo{" "}
            <strong>identitu občana</strong>? Pokud ne, náš{" "}
            {getPartnerSalutation()} vám ji pomůže založit.
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <img src={Step2Img} alt="Krok 2" />
          <div>
            Přihlaste se do{" "}
            <a
              className="link"
              href="https://zadosti.sfzp.cz/AISPortal/LW/Views/Core/Detail?action=get&id=365A80FE-A452-490B-9D9D-63161564A311&idForm=a12b5f7e-bd75-4819-9030-6e87f4410f9f&idbo=b6faa0e4-7fdf-413e-b134-ae1e3516fcb8"
              target="_blank"
              rel="noreferrer"
            >
              Agendového informačního systému (AISP) SFŽP
            </a>{" "}
            pomocí <strong>identity občana</strong> nebo{" "}
            <strong>bankovní identity</strong>. Vytvořte si v AISP účet.
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <img src={Step3} alt="Krok 3" />
          <div>
            V AISP v sekci “Dashboard” vyberte “Podat žádost”. Potvrďte žadatele
            a zvolte, o jakou dotaci chcete žádat.
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <img src={Step4} alt="Krok 4" />
          <div>
            <strong>Vyplňte žádost</strong> v AISP (informace o domě, o
            projektu, atd.) a potvrďte čestná prohlášení. Po vyplnění formuláře{" "}
            <strong>žádost podejte</strong>.
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <img src={Step5} alt="Krok 5" />
          <div>SFŽP žádost zkontroluje a v průměru do 6 týdnů vyřídí.</div>
        </div>
      </Block>

      {shouldShowLeadForm.current && (
        <div className="bg-lightblue">
          <LeadForm
            buttonType="primary"
            icon={IconChat}
            title="Potřebujete pomoct s podáním dotace?"
            stepsDesc={[
              "Pomůžeme založit si bankovní identitu",
              "Doporučíme naše partnery na rekonstrukci",
              "Projdeme s vámi detailně možnosti financování",
            ]}
            reason={Reasons.HowToApply}
          />
        </div>
      )}
    </>
  );
}

export default Step2;
