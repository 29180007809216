import Button from "components/Button";
import classNames from "classnames";
import { ReactNode } from "react";

export interface StepProps {
  title: string;
  description?: string[];
  icon: string;
  button?: {
    isVisible?: boolean;
    label: string;
    labelMobile?: string;
    type?: "primary" | "secondary" | "link";
  } & (
    | { onClick: () => void }
    | { to: string; target?: string; onClick?: () => void }
  );
  active?: boolean;
  transparent?: boolean;
  children?: ReactNode;
}

function Step(props: StepProps) {
  const { button } = props;
  const buttonClassNames =
    "mt-5 md:mt-0 md:absolute bottom-5 md:bottom-10 right-4 right-8";

  return (
    <div
      className={classNames("Step flex", {
        "bg-white md:bg-transparent": !props.transparent,
      })}
    >
      <img
        src={props.icon}
        alt=""
        className={classNames("px-6 hidden md:block", {
          "opacity-30": props.transparent,
        })}
      />

      <div
        className={classNames("flex-1 relative px-8 py-5 md:py-10", {
          "bg-white": !props.transparent,
          "bg-white/50": props.transparent,
          "border-2 border-base-content": props.active,
        })}
      >
        <h4 className="mb-3 flex md:flex-none items-center">
          <img src={props.icon} alt="" className="md:hidden mr-2" />
          {props.title}
        </h4>
        {props.description && (
          <ul className="list-disc pl-4">
            {props.description.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}

        <div className="Step__done-message text-lg leading-5 flex items-center">
          {props.children}
        </div>

        {button && "to" in button && button.isVisible !== false && (
          <Button
            className={buttonClassNames}
            type={button.type ?? "secondary"}
            label={button.label}
            labelMobile={button.labelMobile}
            to={button.to}
            target={button.target}
            onClick={button.onClick}
          />
        )}
        {button && !("to" in button) && button.isVisible !== false && (
          <Button
            className={buttonClassNames}
            type={button.type ?? "secondary"}
            label={button.label}
            labelMobile={button.labelMobile}
            onClick={button.onClick}
          />
        )}
      </div>
    </div>
  );
}

export default Step;
