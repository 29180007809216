import { MeasureKeys, MeasureSetting, ResponseData } from "shared/types";
import Switcher from "components/form/Switcher";
import { measureAdditionalInfo } from "shared/measure";
import Tooltip from "components/Tooltip";
import MeasureMobile from "pages/configurator/steps/result/components/MeasureMobile";
import SwitchersExplanation from "pages/configurator/steps/result/components/SwitchersExplanation";
import { useFormikContext } from "formik";
import { FormData } from "shared/types";

interface MeasuresTableMobileProps {
  setting: MeasureSetting;
  toggle: (key: MeasureKeys) => void;
  response: ResponseData;
}

function MeasuresTableMobile(props: MeasuresTableMobileProps) {
  const { values } = useFormikContext<FormData>();

  return props.response.validSetting ? (
    <div>
      <div className="bg-primary px-4 py-5 mb-5">
        <h3 className="text-white">Možná opatření</h3>
      </div>

      {Object.keys(measureAdditionalInfo).map((m) => {
        const measureKey = m as MeasureKeys;
        const tooltip = measureAdditionalInfo[measureKey].tooltip;

        return (
          props.response.validSetting && (
            <MeasureMobile
              {...props.response.measures[measureKey]}
              disabled={!props.setting[measureKey]}
              key={measureKey}
              className="mb-5"
            >
              <div className="flex justify-between">
                <h5 className="flex items-center gap-2.5">
                  {measureAdditionalInfo[measureKey].label}
                  {tooltip && <Tooltip text={tooltip} />}
                </h5>
                <Switcher
                  checked={props.setting[measureKey]}
                  onChange={() => props.toggle(measureKey)}
                />
              </div>
            </MeasureMobile>
          )
        );
      })}
      {values.permanentResidence &&
        values.economicallyActive &&
        values.children && (
          <MeasureMobile price={props.response.childrenBonus} className="mb-5">
            <h5 className="flex items-center space-x-2.5 py-1.5">
              <span>Bonus na dítě</span>
              <Tooltip text="Podpora cílí na mladé rodiny, proto žadatelé obdrží rodinný bonus ve výši 50 tisíc korun na každé nezaopatřené dítě." />
            </h5>
          </MeasureMobile>
        )}
      <MeasureMobile price={props.response.constructionPrice} className="mb-5">
        <h5 className="flex items-center space-x-2.5 py-1.5">
          <span>+ Stavební úpravy</span>
          <Tooltip
            text="Stavební úpravy tvoří obvykle 15 % z celkové ceny rekonstrukce.
                        Jedná se o menší rezervu na stavební úpravy, které nesouvisí s úspornými opatřeními,
                        nejdou předem odhadnout a vyplynou zpravidla při rekonstrukci."
          />
        </h5>
      </MeasureMobile>
      <SwitchersExplanation />
    </div>
  ) : null;
}

export default MeasuresTableMobile;
