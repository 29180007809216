import { ReactNode } from "react";
import { usePageTitle } from "helpers/usePageTitle";

interface PageProps {
  children: ReactNode;
  title: string;
}

function Page(props: PageProps) {
  usePageTitle(props.title);

  return (
    <div className="max-w-6xl m-auto sm:px-4 sm:py-8">{props.children}</div>
  );
}

export default Page;
