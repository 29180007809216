import { useFormikContext } from "formik";
import Block from "components/Block";
import PictureSelect from "components/form/PictureSelect";
import PictureOption from "components/form/PictureOption";
import Roof1 from "assets/roofs/roof-a.svg";
import Roof2 from "assets/roofs/roof-b.svg";
import Roof3 from "assets/roofs/roof-c.svg";
import Roof1Hover from "assets/roofs/roof-a-hover.svg";
import Roof2Hover from "assets/roofs/roof-b-hover.svg";
import Roof3Hover from "assets/roofs/roof-c-hover.svg";
import Roof1Selected from "assets/roofs/roof-a-selected.svg";
import Roof2Selected from "assets/roofs/roof-b-selected.svg";
import Roof3Selected from "assets/roofs/roof-c-selected.svg";
import Radio from "components/form/Radio";
import { FormData, RoofTypeEnum } from "shared/types";

function Step3() {
  const { values } = useFormikContext<FormData>();

  return (
    <>
      <h2>Přesuneme se na střechu</h2>
      <Block>
        <PictureSelect cols={3} name="roofType" label="Jaká je ta vaše?">
          <PictureOption
            src={Roof1}
            srcHover={Roof1Hover}
            srcSelected={Roof1Selected}
            caption="rovná střecha"
            value={RoofTypeEnum.Flat}
          />
          <PictureOption
            src={Roof2}
            srcHover={Roof2Hover}
            srcSelected={Roof2Selected}
            caption="sedlová střecha"
            value={RoofTypeEnum.Gable}
          />
          <PictureOption
            src={Roof3}
            srcHover={Roof3Hover}
            srcSelected={Roof3Selected}
            caption="komplikovaná střecha"
            value={RoofTypeEnum.Complicated}
          />
        </PictureSelect>
      </Block>
      {values.roofType !== undefined &&
        values.roofType !== RoofTypeEnum.Flat && (
          <Block headline="Máte obydlené podkroví?">
            <Radio inline name="attic" />
          </Block>
        )}
    </>
  );
}

export default Step3;
