import "./Savings.scss";
import { useState } from "react";
import Button from "components/Button";
import Block from "components/Block";
import Input from "components/form/Input";
import Radio from "components/form/Radio";
import { useFormikContext } from "formik";
import {
  FuelType,
  HeatingSourceEnum,
  HotWaterSourceEnum,
  PaymentFrequency,
  PrepaymentsBalance,
  SavingsFormData,
} from "shared/types";
import { getTouchedFromErrors } from "helpers/form";
import axios from "axios";
import {
  heatingSourceFuelMap,
  hotWaterSourceFuelMap,
  measureSavingsSetting,
} from "shared/measure";
import Reults from "pages/configurator/steps/savings/components/Results";
import { isNotProduction } from "helpers/environment";

const energySourceTransMap: Record<string, string> = {
  [FuelType.GAS]: "plyn",
  [FuelType.ELECTRICITY]: "elektřinu",
  [FuelType.COAL]: "uhlí",
  [FuelType.BIOMASS]: "boimasu",
};

interface FormDataProps {
  heatingSource: HeatingSourceEnum;
  hotWaterSource: HotWaterSourceEnum;
  hasFireplace: boolean;
}
/**
 * Retunes map of used Energy Sources used
 * @param values
 * @returns Object
 */
function getUsedFuel(values: FormDataProps): Record<FuelType, boolean> {
  const { heatingSource, hotWaterSource, hasFireplace } = values;
  const heatingSourceType = heatingSource
    ? heatingSourceFuelMap[heatingSource]
    : null;
  const hotWaterSourceType = hotWaterSource
    ? hotWaterSourceFuelMap[hotWaterSource]
    : null;

  const sources = {
    [FuelType.GAS]:
      heatingSourceType === FuelType.GAS || hotWaterSourceType === FuelType.GAS,
    [FuelType.ELECTRICITY]:
      heatingSourceType === FuelType.ELECTRICITY ||
      hotWaterSourceType === FuelType.ELECTRICITY,
    [FuelType.COAL]: heatingSourceType === FuelType.COAL,
    [FuelType.BIOMASS]: hasFireplace || heatingSourceType === FuelType.BIOMASS,
    [FuelType.OTHER]: heatingSourceType === FuelType.OTHER,
  };

  return sources;
}
type SavingStepProps = FormDataProps & SavingsFormData;
interface SavingsResponse {
  oldYearlyCost: number;
  newYearlyCost: number;
  newMonthlyCost: number;
  monthlySavings: number;
}

export function SavingsStep<FormType extends SavingStepProps>(props: any) {
  const [savings, setSavings] = useState<SavingsResponse | null>(null);
  const { values, validateForm, setTouched } = useFormikContext<FormType>();
  const showExpenses = values.expenses !== undefined ? values.expenses : true;
  const usedFuels = getUsedFuel(values);
  const scrollFn = (top: number) =>
    window.scrollTo({ top, left: 0, behavior: "smooth" });

  const calculate = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setTouched(getTouchedFromErrors(errors));
      const topElm = document.querySelector(".top-anchor");
      const top = topElm
        ? window.scrollY + topElm.getBoundingClientRect().top - 20
        : 0;
      scrollFn(top);
      return false;
    } else {
      const response = axios.post("/compute-savings", {
        formData: values,
        measureSetting: measureSavingsSetting,
      });

      // TODO: typy
      response
        .then(({ data }) => {
          isNotProduction && console.log(data.result);
          setSavings(data.result);
          const bottom =
            document.getElementById("saivng-calc-result")?.offsetTop;
          bottom && scrollFn(bottom);
        })
        .catch((error) => {
          // TODO: jak odchytit chyby
          isNotProduction && console.log(error);
        });
      // TODO: odchyt chyb
    }
  };

  return (
    <>
      <h2>Spočítejme, kolik uspoříte na energiích po rekonstrukci.</h2>
      <Block
        headline="Dokážete odhadnout svoje současné náklady na vytápění a ohřev vody? K&nbsp;výpočtu potřebujeme alespoň jeden údaj o vaší spotřebě."
        className="top-anchor"
      >
        <Radio name="expenses" inline />
      </Block>

      {showExpenses && (
        <div className="Savings">
          {(usedFuels.GAS || usedFuels.OTHER) && (
            <Prepayment
              source={FuelType.GAS}
              balance={values.prepaymentsBalanceGas}
            />
          )}
          {(usedFuels.ELECTRICITY || usedFuels.OTHER) && (
            <Prepayment
              source={FuelType.ELECTRICITY}
              balance={values.prepaymentsBalanceElectricity}
            />
          )}
          {(usedFuels.COAL || usedFuels.OTHER) && (
            <Block headline="Roční útrata za uhlí">
              <Input name="prepaymentsCoalCost" type="number" unit="Kč" />
            </Block>
          )}
          {(usedFuels.BIOMASS || usedFuels.OTHER) && (
            <Block
              headline="Roční útrata za biomasu"
              tooltip="Biomasou myslíme samotné dřevo nebo brikety a pelety vyrobené z různých zbytků dřeva."
            >
              <Input name="prepaymentsBiomassCost" type="number" unit="Kč" />
            </Block>
          )}
          <div id="saivng-calc-result">
            <Button
              label="Spočítat budoucí úsporu"
              onClick={calculate}
              isSubmit
              className="w-full md:w-auto"
            />
          </div>
          {savings && <Reults {...savings} />}
        </div>
      )}
    </>
  );
}

interface PrepaymentProps {
  source: FuelType;
  balance: PrepaymentsBalance;
}
function Prepayment({ source, balance }: PrepaymentProps) {
  const sourceTitle = energySourceTransMap[source];
  const getFieldName = (fieldName: string, suffix?: string): string => {
    const capitalizedSource =
      source.charAt(0).toUpperCase() + source.toLowerCase().slice(1);
    return fieldName + capitalizedSource + (suffix ?? "");
  };
  return (
    <>
      <Block headline={`Zálohy za ${sourceTitle}`}>
        <Radio
          // @ts-ignore
          name={getFieldName("prepaymentsFrequency")}
          options={[
            { value: PaymentFrequency.MONTHLY, label: "měsíční" },
            { value: PaymentFrequency.QUATERLY, label: "čtvrtletní" },
          ]}
        />

        <Input
          name={getFieldName("prepayments", "Cost")}
          type="number"
          unit="Kč"
        />
      </Block>

      <Block
        headline={`Měli jste v posledním vyúčtování za ${sourceTitle} přeplatek/nedoplatek?`}
      >
        <Radio
          // @ts-ignore
          name={getFieldName("prepaymentsBalance")}
          options={[
            {
              value: PrepaymentsBalance.MATCHING,
              label: "ne, zálohy odpovídaly spotřebě",
            },
            { value: PrepaymentsBalance.OVERPAID, label: "přeplatek" },
            { value: PrepaymentsBalance.UNPAID, label: "nedoplatek" },
            {
              value: PrepaymentsBalance.DONTKNOW,
              label: "nepamatuji si",
            },
          ]}
        />

        {balance &&
          balance !== PrepaymentsBalance.MATCHING &&
          balance !== PrepaymentsBalance.DONTKNOW && (
            <Input
              name={getFieldName("prepaymentsBalance", "Cost")}
              type="number"
              unit="Kč"
            />
          )}
      </Block>
    </>
  );
}
