import Tip from "components/layout/Tip";
import Navigation from "components/layout/Navigation";
import Steps from "components/layout/Steps";

interface LeftBarProps<T extends number> {
  actualStep: T;
  steps: { [key in T]: string | undefined };
  tips?: { [key in T]: string | string[] | null };
  tipHeadline: string;
  mobileTipVisible?: boolean;
  toggleMobileTipVisible?: () => void;
  goToPreviousStep?: () => void;
}

function LeftBar<T extends number>(props: LeftBarProps<T>) {
  return (
    <div className="col-span-full lg:col-span-3 sticky top-0 sm:static z-10">
      <div
        className="side-menu lg:sticky top-0 flex flex-col justify-between"
        data-theme="dark"
      >
        <Steps {...props} />
        <Tip
          tips={props.tips}
          tipHeadline={props.tipHeadline}
          step={props.actualStep}
          mobileTipVisible={props.mobileTipVisible}
        />
      </div>
      {/* Fake navigation on mobile – see <Tip /> for more info */}
      {props.mobileTipVisible && (
        <div className="lg:hidden">
          <Navigation
            nextLabel="Pokračovat"
            nextClick={props.toggleMobileTipVisible}
            backLabel="Zpět"
            backIcon="chevron-left"
            backClick={props.goToPreviousStep}
          />
        </div>
      )}
    </div>
  );
}

export default LeftBar;
