export enum StepEnum {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
  Step5 = 5,
  GrantType = 6,
  Savings = 7,
  Results = 8,
  Financing = 9,
  Contact = 10,
  ThankYou = 11,
}
