import { Reasons } from "./reasons";

export enum PrepaymentsBalance {
  OVERPAID = "přeplatek",
  UNPAID = "nedoplatek",
  MATCHING = "ne, zálohy odpovídaly spotřebě",
  DONTKNOW = "nepamatuji se",
}
export enum FuelType {
  GAS = "GAS",
  ELECTRICITY = "ELECTRICITY",
  COAL = "COAL",
  BIOMASS = "BIOMASS",
  OTHER = "OTHER",
}

export enum PaymentFrequency {
  QUATERLY = "měsíční",
  MONTHLY = "čtvrtletní",
}

export interface SavingsFormData {
  expenses: boolean;
  prepaymentsFrequencyGas: PaymentFrequency.QUATERLY | PaymentFrequency.MONTHLY;
  prepaymentsFrequencyElectricity:
    | PaymentFrequency.QUATERLY
    | PaymentFrequency.MONTHLY;
  prepaymentsGasCost: number;
  prepaymentsElectricityCost: number;
  prepaymentsBalanceGas: PrepaymentsBalance;
  prepaymentsBalanceGasCost: number;
  prepaymentsBalanceElectricity: PrepaymentsBalance;
  prepaymentsBalanceElectricityCost: number;
  prepaymentsCoalCost: number;
  prepaymentsBiomassCost: number;
}

interface BaseFormData {
  attic?: boolean;
  area: number;
  basement: boolean;
  children: number;
  doors: number;
  grant: number;
  economicallyActive: boolean;
  floors: number;
  fvInstalled: boolean;
  grantType: GrantTypeEnum;
  hasFireplace: boolean;
  heatedBasement?: boolean;
  heatingSource: HeatingSourceEnum;
  heatingSourceAge?: number;
  hotWaterSource: HotWaterSourceEnum;
  hotWaterSourceAge: number;
  houseAge: HouseAgeEnum;
  houseMembers: number;
  permanentResidence: boolean;
  roofType: RoofTypeEnum;
  windowsSmall: number;
  windowsMedium: number;
  windowsLarge: number;
}

export type CompleteBaseFormData = BaseFormData &
  SavingsFormData & {
    houseType: HouseTypeEnum;
  };

interface LoanData {
  loanLength: number;
  ownSavings: number;
}

export type ContactDataNoPartner = {
  partner: false;
  tel: string;
  agreement: boolean;
};
export type ContactDataWithPartner = {
  partner: true;
  partnerName: string;
  partnerTel: string;
  partnerEmail: string;
  partnerType: PartnerParamType;
};

export type ContactData = {
  email: string;
} & (ContactDataNoPartner | ContactDataWithPartner);

export type FormData = Partial<
  BaseFormData & SavingsFormData & LoanData & ContactData
>;
export type ValidatedFormData = BaseFormData & LoanData & Partial<ContactData>;
export type CompleteFormData = CompleteBaseFormData &
  LoanData &
  Partial<ContactData>;
export type FormAndContactData = BaseFormData & LoanData & ContactData;
export type CompleteFormAndContactData = CompleteBaseFormData &
  LoanData &
  ContactData;

export type LeadOptionalData = {
  isBabickaEligible?: boolean;
};
export type LeadData = {
  reason: Reasons;
  tel: string;
} & LeadOptionalData;

export interface Measure {
  price: number;
  grant: number;
  saving: number;
}

export type MeasureKeys =
  | "wallInsulation"
  | "roofInsulation"
  | "windows"
  | "floorInsulation"
  | "ventilation"
  | "heatPump"
  | "gasCondensingBoiler"
  | "fv"
  | "documentation";

export type SavingsKeys =
  | "wallInsulation"
  | "floorInsulation"
  | "roofInsulation"
  | "windows"
  | "ventilation"
  | "hotWater"
  | "other";

export type Measures = { [key in MeasureKeys]: Measure };

export type MeasureSetting = { [key in MeasureKeys]: boolean };

export type RequestData = {
  formData: ValidatedFormData;
  measureSetting: MeasureSetting;
};

export type CompleteRequestData = {
  formData: CompleteFormData;
  measureSetting: MeasureSetting;
};

export type CompleteRequestDataWithContact = {
  formData: CompleteFormAndContactData;
  measureSetting: MeasureSetting;
};

export type HeatTransferLabel = "A" | "B" | "C" | "D" | "E" | "F" | "G";

export type ResponseSavingsData =
  | ({
      newTotalCost: number;
      oldFuelCost: number;
      monthlySavings: number;
    } & { [key in MeasureKeys]?: Measure })
  | null;

export type ResponseData =
  | {
      validSetting: true;
      isBabickaEligible: boolean;
      measures: Measures;
      childrenBonus: number;
      constructionPrice: number;
      total: Measure;
      totalGrant: { [key in GrantTypeEnum]: number };
      heatTransferCoefficient: {
        before: HeatTransferLabel;
        after: HeatTransferLabel;
      };
      enteredExpenses: boolean;
    }
  | { validSetting: false };

export enum HouseAgeEnum {
  Before1964 = "Before1964",
  Since1965 = "Since1965",
  Since1977 = "Since1977",
  Since1991 = "Since1991",
  Since2002 = "Since2002",
}

export enum HouseTypeEnum {
  Terraced = "Terraced",
  Family = "Family",
  Village = "Village",
  Complicated = "Complicated",
  Bungalow = "Bungalow",
  LShape = "LShape",
}

export enum RoofTypeEnum {
  Flat = "Flat",
  Gable = "Gable",
  Complicated = "Complicated",
}

export enum HeatingSourceEnum {
  GasBoiler = "GasBoiler",
  GasCondensingBoiler = "GasCondensingBoiler",
  ElectricBoiler = "ElectricBoiler",
  CoalBoiler = "CoalBoiler",
  BiomassBoiler = "BiomassBoiler",
  WaterHeater = "WaterHeater",
  HeatPump = "HeatPump",
  Fireplace = "Fireplace",
  Other = "Other",
}

export enum HeatingSourceAgeEnum {
  LessThan5 = "LessThan5",
  Between5And15 = "Between5And15",
  MoreThan15 = "MoreThan15",
}

export enum HotWaterSourceEnum {
  ElectricBoiler = "ElectricBoiler",
  HeatPump = "HeatPump",
  GasCondensingBoiler = "GasCondensingBoiler",
  WaterHeater = "WaterHeater",
}

export enum SelectedPrimaryHeatingSourceEnum {
  Original = "Original",
  HeatPump = "HeatPump",
  GasCondensingBoiler = "GasCondensingBoiler",
}

export enum SelectedSecondaryElectricitySourceEnum {
  Photovoltaics = "Photovoltaics",
  None = "None",
}

export enum SelectedSecondaryHeatingSourceEnum {
  Fireplace = "Fireplace",
  None = "None",
}

export enum SelectedInsulationTypeEnum {
  Thorough = "Thorough",
  Standard = "Standard",
  None = "None",
}

export enum SelectedWindowsTypeEnum {
  Triple = "Triple",
  Double = "Double",
  Old = "Old",
}

export enum SelectedVentilationTypeEnum {
  Natural = "Natural",
  WithRecuperation = "WithRecuperation",
}

export type Setting = {
  wallInsulationType: SelectedInsulationTypeEnum;
  floorInsulationType: SelectedInsulationTypeEnum;
  roofInsulationType: SelectedInsulationTypeEnum;
  windowsType: SelectedWindowsTypeEnum;
  ventilationType: SelectedVentilationTypeEnum;
  primarySource: SelectedPrimaryHeatingSourceEnum;
  secondaryElectricitySource: SelectedSecondaryElectricitySourceEnum;
  secondaryHeatingSource: SelectedSecondaryHeatingSourceEnum;
};

export type DimensionsAreaType = {
  windowsSmall: number;
  windowsMedium: number;
  windowsLarge: number;
  doors: number;
};

export interface Constants {
  windowsPricePerM2: number;
  windowsGrantPerM2: number;
  roofInsulationPricePerM2: number;
  roofInsulationGrantPerM2: number;
  wallHeightM: number;
  wallInsulationPricePerM2: number;
  wallInsulationGrantPerM2: number;
  floorInsulationPricePerM2: number;
  floorInsulationGrantPerM2: number;
  ventilationPrice: number;
  ventilationGrant: number;
  gasCondensingBoilerPrice: number;
  heatPumpPrice: number;
  heatPumpGrant: number;
  fvPrice: number;
  fvGrantNoHeatPump: number;
  fvGrantHeatPump: number;
  fvGrantInsulationBonus: number;
  documentationGrant: number;
  energyCoefficient: {
    [key in HouseTypeEnum]: { [key in HouseAgeEnum]: number };
  };
  energyDoubleWindows: number;
  energyTripleWindows: number;
  energyOldWindows: number;
  sourceCoefficient: {
    [HeatingSourceEnum.GasBoiler]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.GasCondensingBoiler]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.ElectricBoiler]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.CoalBoiler]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.BiomassBoiler]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.WaterHeater]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    [HeatingSourceEnum.HeatPump]: {
      [key in HeatingSourceAgeEnum]: number;
    };
    HeatPumpHotWater: {
      [key in HeatingSourceAgeEnum]: number;
    };
    Fireplace: {
      [key in HeatingSourceAgeEnum]: number;
    };
  };
  heatingSystemCoefficient: {
    [key in HeatingSourceAgeEnum]: number;
  };
  mediaPrice: {
    [HeatingSourceEnum.GasBoiler]: number;
    [HeatingSourceEnum.GasCondensingBoiler]: number;
    [HeatingSourceEnum.ElectricBoiler]: number;
    [HeatingSourceEnum.CoalBoiler]: number;
    [HeatingSourceEnum.BiomassBoiler]: number;
    [HeatingSourceEnum.WaterHeater]: number;
    [HeatingSourceEnum.HeatPump]: number;
    [HeatingSourceEnum.Fireplace]: number;
  };
  fvHeatingAbsolute: number;
  newHeatingSystemEfficiency: number;
  newHeatPumpHeatingEfficiency: number;
  roofSizeCoefficient: number;
  roofInsulationLightEnergy: number;
  roofInsulationMediumEnergy: number;
  roofInsulationThoroughEnergy: number;
  roofInsulationNoneEnergy: number;
  wallInsulationNoneEnergy: number;
  wallInsulationLightEnergy: number;
  wallInsulationMediumEnergy: number;
  wallInsulationThoroughEnergy: number;
  wallInsulationBestEnergy: number;
  floorInsulationNoneEnergy: number;
  floorInsulationStandardEnergy: number;
  floorInsulationThoroughEnergy: number;
  fvElectricityAbsolute: number;
  electricityD02d: number;
  ventilationCoefficient: number;
  areaHeatedCoefficient: number;
  naturalVentilationCoefficient: number;
  hotWaterNeedPerPerson: number;
  fvTVAbsolute: number;
  lightCoefficient: number;
  ACConsumption: number;
  heatTransferCoefficient: {
    floor: {
      noInsulation: number;
      insulation: number;
    };
    walls: {
      noInsulation: number;
      complexInsulation: number;
    };
    roof: {
      noInsulation: number;
      complexInsulation: number;
      reference: number;
    };
    windows: {
      old: number;
      double: number;
      triple: number;
      reference: number;
    };
  };
  uem: {
    [key in HeatTransferLabel]: number;
  };
  dimensions: DimensionsAreaType;
  roundingPrecission: number;
}

export enum GrantTypeEnum {
  NZUS = "NZUS",
  Babicka = "Babicka",
}

export type Loan = {
  name: string;
  trackingName: string;
  pluses: string[];
  minuses: string[];
  link: string;
  defaultInterest: number;
  interestExceptions: { maxAmount: number; interest: number }[];
  interestFixed: boolean;
  interestFrom: boolean;
  minAmount: number | null;
  maxAmount: number | null;
  minLength: number | null;
  maxLength: number | null;
  withSavings: boolean;
  nameCode: string;
  onlyWithBabicka: boolean;
};

export type PartnerParamType = string | boolean;