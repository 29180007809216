import classNames from "classnames";
import { useFormikContext } from "formik";
import { FormData } from "shared/types";

interface RadioProps {
  name: keyof FormData;
  options?: { value: string; label: string }[];
  inline?: boolean;
}

function Radio(props: RadioProps) {
  const { values, setFieldValue, touched, errors } =
    useFormikContext<FormData>();
  const value = values[props.name];
  const error = touched[props.name] && errors[props.name];
  const options = props.options || [
    { value: true, label: "Ano" },
    { value: false, label: "Ne" },
  ];

  return (
    <div className="form-control">
      <div
        className={classNames({
          flex: props.inline,
          "space-x-4": props.inline,
        })}
      >
        {options.map((option) => (
          <label
            className="label cursor-pointer justify-start py-1 space-x-2"
            key={option.value.toString()}
          >
            <input
              type="radio"
              name={props.name}
              className={classNames("radio", "checked:bg-primary", {
                "radio-error": error,
              })}
              checked={value === option.value}
              onChange={() => setFieldValue(props.name, option.value)}
            />
            <span className="label-text">{option.label}</span>
          </label>
        ))}
      </div>
      {error && <div className="text-sm text-error mt-2">{error}</div>}
    </div>
  );
}

export default Radio;
