import { HeatTransferLabel } from "shared/types";
import { getHeatTransferCoefficientLabel } from "shared/heatTransferCoefficient";
import A from "assets/rating/a.svg";
import B from "assets/rating/b.svg";
import C from "assets/rating/c.svg";
import D from "assets/rating/d.svg";
import E from "assets/rating/e.svg";
import F from "assets/rating/f.svg";
import G from "assets/rating/g.svg";

type HeatTransferBadgeProps = {
  rating: HeatTransferLabel;
};

function getBadgeIcon(rating: HeatTransferLabel) {
  switch (rating) {
    case "A":
      return A;
    case "B":
      return B;
    case "C":
      return C;
    case "D":
      return D;
    case "E":
      return E;
    case "F":
      return F;
    case "G":
      return G;
  }
}

function HeatTransferBadge(props: HeatTransferBadgeProps) {
  return (
    <div className="flex justify-between items-center">
      <span>{getHeatTransferCoefficientLabel(props.rating)}</span>
      <img
        src={getBadgeIcon(props.rating)}
        alt={getHeatTransferCoefficientLabel(props.rating)}
      />
    </div>
  );
}

export default HeatTransferBadge;
