import { useFormikContext } from "formik";
import classNames from "classnames";
import Tooltip from "components/Tooltip";
import ValidationSuccess from "assets/icons/validation-success.svg";
import ValidationError from "assets/icons/validation-error.svg";
import { AsYouType } from "libphonenumber-js";
import { ChangeEvent, FocusEvent } from "react";

type InputProps<T extends { [key: string]: unknown }> = {
  label?: string;
  placeholder?: string;
  tooltip?: string;
  type?: "text" | "number" | "email" | "tel" | "password";
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
} & (
  | {
      name: keyof T;
      error?: string;
    }
  | {
      value: string | number;
      onChange?: (value: string | number) => void;
      error?: string;
    }
);

function Input<T extends { [key: string]: unknown }>(props: InputProps<T>) {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext<T>();
  const rawValue = "name" in props ? values[props.name] : props.value;
  const value = (typeof rawValue === "number" ? rawValue : rawValue || "") as
    | string
    | number;
  const changeHandler = (value: string | number) =>
    "name" in props
      ? setFieldValue(props.name as string, value)
      : props.onChange?.(value);
  const touchedValue = "name" in props && touched[props.name];
  const errorMessage = "name" in props ? errors[props.name] : props.error;
  const error = touchedValue && errorMessage;
  const isValid = !error && touchedValue;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value =
      props.type === "number"
        ? Number(e.target.value).toString()
        : e.target.value;
    changeHandler(value);
  };
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (props.type === "tel" && !error) {
      const value = new AsYouType().input(e.target.value);
      changeHandler(value);
    }
    handleBlur(e);
  };

  return (
    <div className={classNames("form-control", "w-full", props.className)}>
      {props.label && (
        <label className="label">
          <span className="label-text">{props.label}</span>
        </label>
      )}
      <div className="flex items-center space-x-2.5">
        <div
          className={classNames("flex-1", "relative", {
            "max-w-md": !props.fullWidth,
          })}
        >
          <input
            name={"name" in props ? (props.name as string) : undefined}
            type={props.type || "text"}
            min={props.min && props.min >= 0 ? props.min : 0}
            max={props.max}
            step={props.step}
            placeholder={
              props.placeholder || (props.type === "number" ? "0" : "")
            }
            className={classNames("input", "input-bordered", "w-full", {
              "input-error": error,
              "pr-10": props.unit,
            })}
            disabled={props.disabled}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            // onWheel={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   return false;
            // }}
          />
          {props.unit && (
            <div className="absolute top-0.5 right-0.5 pointer-events-none p-3 text-border-color">
              {props.unit}
            </div>
          )}
        </div>
        {isValid && <img src={ValidationSuccess} alt="Valid" />}
        {error && <img src={ValidationError} alt="Invalid" />}
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </div>
      {error && (
        <label className="label">
          <span className="label-text-alt text-sm text-error">
            {error as string}
          </span>
        </label>
      )}
    </div>
  );
}

export default Input;
