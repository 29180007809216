import Block from "components/Block";
import { useFormikContext } from "formik";
import { GrantTypeEnum } from "shared/types";
import Radio from "components/form/Radio";
import GrantTypeCard from "pages/configurator/components/GrantTypeCard";
import CheckCircleGreen from "assets/icons/check-circle-green.svg";
import Select from "components/form/Select";
import { getGrantTypes } from "shared/grantTypes";
import { useEffect } from "react";

export interface GrantFormData {
  permanentResidence?: boolean;
  economicallyActive?: boolean;
  children?: number;
}

export interface GrantTypeStepProps {
  skipChildren?: boolean;
}

const childrenSelectOptions = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10 a více",
};

function GrantTypeStep<FormType extends GrantFormData>(
  props: GrantTypeStepProps,
) {
  const { values, setFieldValue } = useFormikContext<FormType>();
  const grantTypes = getGrantTypes(values);

  useEffect(() => {
    if (values.economicallyActive && values.permanentResidence) {
      setFieldValue("grantType", GrantTypeEnum.Babicka);
    } else {
      setFieldValue("grantType", GrantTypeEnum.NZUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <h2>Podívejme se, jaká dotace je pro vás vhodná</h2>
      <Block headline="Plánujete v domě, jehož rekonstrukci teď počítáme, bydlet dalších deset let?">
        <Radio name="permanentResidence" inline />
      </Block>
      <Block
        headline="Jste ekonomicky aktivní?"
        tooltip="Za ekonomicky aktivní se považují zaměstnanci, zaměstnavatelé, osoby samostatně výdělečně činné, pracující důchodci, pracující studenti a učni, rodiče na mateřské dovolené."
      >
        <Radio name="economicallyActive" inline />
      </Block>
      <Block headline="Splňujete podmínky pro následující dotaci/e">
        <div className="grid sm:grid-cols-2 gap-4">
          {Object.entries(grantTypes).map(([key, value]) => (
            <GrantTypeCard key={key} type="static" {...value} />
          ))}
        </div>
        {grantTypes[GrantTypeEnum.Babicka].available && (
          <div className="flex gap-4 items-center mt-14">
            <img src={CheckCircleGreen} alt="Attribute" />
            <p>
              Oba dotační programy můžete čerpat pro{" "}
              <strong>stejná úsporná opatření</strong> a poskytují{" "}
              <strong>stejnou výši finanční podpory</strong>.
            </p>
          </div>
        )}
      </Block>
      {!props.skipChildren && grantTypes[GrantTypeEnum.Babicka].available && (
        <>
          <h5 className="mb-6">
            Podmínky pro získání finančního bonusu v rámci dotace Oprav dům po
            babičce
          </h5>
          <Block
            headline="Kolik máte nezaopatřených dětí?"
            copy="Za každé nezaopatřené dítě obdržíte rodinný bonus 50 000 Kč"
            tooltip="Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání."
          >
            <Select
              name="children"
              // placeholder="Vyberte počet dětí"
              options={childrenSelectOptions}
            />
          </Block>
        </>
      )}
    </>
  );
}

export default GrantTypeStep;
