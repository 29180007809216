import { HeatTransferLabel } from "./types";

export function getHeatTransferCoefficientLabel(rating: HeatTransferLabel) {
  switch (rating) {
    case "A":
      return "Mimořádně úsporná";
    case "B":
      return "Velmi úsporná";
    case "C":
      return "Úsporná";
    case "D":
      return "Méně úsporná";
    case "E":
      return "Nehospodárná";
    case "F":
      return "Velmi nehospodárná";
    case "G":
      return "Mimořádně nehospodárná";
  }
}
