import ChevronDown from "assets/icons/chevron-down-blue.svg";
import Switcher from "components/form/Switcher";
import { useState } from "react";
import classNames from "classnames";

function SwitchersExplanation() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classNames("collapse", {
        "collapse-open": expanded,
      })}
    >
      <div
        className="collapse-title flex min-h-fit space-x-4 items-baseline p-0"
        onClick={() => setExpanded((e) => !e)}
      >
        <span className="link-primary">
          Proč jsou některé přepínače zelené a jiné šedé?
        </span>
        <img src={ChevronDown} alt="Show more" className="collapse-icon" />
      </div>
      <div className="collapse-content">
        <div className="pt-4 mb-6">
          <Switcher
            label="Zelený přepínač"
            readOnly
            checked
            className="font-bold mb-1"
          />
          <p>
            Opatření doporučujeme na základě analýzy domu z vašich odpovědí.
            Opatření můžete dát pryč, ale{" "}
            <strong>rekonstrukce nebude tak úsporná</strong>.
          </p>
        </div>
        <div>
          <Switcher
            label="Šedý přepínač"
            readOnly
            checked={false}
            className="font-bold mb-1"
          />
          <p>
            Opatření by pro vás bylo nadbytečné nebo se vylučuje s jiným
            opatřením.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SwitchersExplanation;
