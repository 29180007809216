import Tooltip from "components/Tooltip";

function Step1() {
  return (
    <>
      <h2 className="mb-6">Dokumenty potřebné pro podání žádosti o dotaci</h2>
      <h4 className="mb-8 lg:mb-14">
        Uschovejte si smlouvy, účetní doklady a doklady prokazující realizaci.
        Dokumenty jsou pro všechny varianty dotací NZÚ stejné.
      </h4>
      <div className="mb-8">
        <h5 className="mb-4">K žádosti o dotaci přikládáte:</h5>
        <ul className="list-disc pl-4 space-y-2.5">
          <li>
            Odborný posudek
            <Tooltip
              className="ml-4"
              text="Odborný posudek v oblasti novostavby a zateplení se zpravidla skládá ze dvou částí – projektové dokumentace a energetického hodnocení budovy, u ostatních opatření je jednodušší. Posudek musí být zpracován osobou s příslušnou autorizací či oprávněním."
            ></Tooltip>
          </li>
          <li>Dokument prokazující vlastnictví bankovního účtu</li>
          <li>
            Dokumenty specifické pro váš dům
            <Tooltip
              className="ml-4"
              // TODO formátování tooltipu
              text="Například: Schválení navrhovaných úprav orgánem památkové péče, schválení navrhovaných úprav orgánem ochrany přírody, výpočet environmentálně šetrného řešení, prokázání trvalého pobytu v rekreačním objektu, např. občanský průkaz, dokument prokazující výchovu nezletilého dítěte (např. rodný list)."
            />
          </li>
        </ul>
      </div>
      <div className="mb-6">
        <h5 className="mb-4">
          Po dokončení rekonstrukce doložíte realizaci opatření:
        </h5>
        <ul className="list-disc pl-4 space-y-2.5">
          <li>Závěrečná zpráva odborného technického dozoru</li>
          <li>Fotodokumentace provedeného opatření</li>
          <li>Zpráva o instalaci zařízení</li>
          <li>Technické listy použitých výrobků</li>
          <li>
            Dokumenty specifické pro vaši situaci{" "}
            <Tooltip
              className="ml-4"
              text="Například: Dokumenty k veřejné podpoře, dokumenty k veřejným zakázkám, prohlášení k vyloučení střetu zájmů, oznámení spolufinancování projektu, čestné prohlášení o zapsání skutečných majitelů, prokázání trvalého pobytu žadatele v domě pro rodinnou rekreaci."
            />
          </li>
        </ul>
      </div>
      <div>
        S potřebnými dokumenty vám zpravidla pomůže naše partnerská stavební
        firma, proto jsme jejich seznam zkrátili.
      </div>
    </>
  );
}

export default Step1;
