import { formatNumber, round } from "shared/number";

interface SavingsResult {
  oldYearlyCost: number;
  newMonthlyCost: number;
  monthlySavings: number;
}

function Reults(props: SavingsResult) {
  const { oldYearlyCost, newMonthlyCost, monthlySavings } = props;
  const oldMonthlyCost = oldYearlyCost / 12;
  const savingPercentile = round(
    (1 - newMonthlyCost / oldMonthlyCost) * 100,
    0,
  );
  return (
    <div className="mt-14 py-4">
      <h4 className="text-2xl font-normal">
        {monthlySavings < 500 ? (
          <>
            Ups, vypadá to, že máte hodně specifický dům. S výpočtem pro váš dům
            vám ale pomůže náš partnerský energetický specialista.
          </>
        ) : (
          <>
            Vypočtená budoucí úspora. Komplexní rekonstrukcí
            ušetříte&nbsp;až&nbsp;
            {savingPercentile < 0
              ? 0
              : savingPercentile > 100
                ? 100
                : savingPercentile}
            %!
          </>
        )}
      </h4>
      {monthlySavings >= 500 && (
        <div className="Savings--results flex flex-col md:flex-row content-between items-center">
          <div className="Savings--results__item">
            <span>stávající měsíční náklady</span>
            <b>{formatNumber(round(oldMonthlyCost, 0), "Kč")}</b>
          </div>
          <span className="text-3xl">-</span>
          <div className="Savings--results__item">
            <span>budoucí náklady</span>
            <b>{formatNumber(round(newMonthlyCost, 0), "Kč")}</b>
          </div>
          <span className="text-3xl">=</span>
          <div className="Savings--results__item --saving">
            <span>měsíční úspora</span>
            <b>až {formatNumber(monthlySavings, "Kč")}</b>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reults;
