import classNames from "classnames";
import Button from "components/Button";
import Input from "components/form/Input";
import { Form, Formik } from "formik";
import { DEFAULT_PHONE_NUMBER, leadFormValidationSchema } from "helpers/form";
import { useState } from "react";
import { useAppStore } from "stores/appStore";
import Checkbox from "components/form/Checkbox";
import axios, { AxiosError } from "axios";
import Loading from "components/layout/Loading";
import { Reasons } from "shared/reasons";
import { ContactDataNoPartner, LeadData, LeadOptionalData } from "shared/types";
import { trackLeadFormSent } from "helpers/analytics";

interface FormProps {
  title: string;
  stepsDesc?: string[];
  handleSubmit?: (values: ContactDataNoPartner) => void;
  buttonType?: "primary" | "secondary";
  icon: string;
  successMessage?: string;
  reason: Reasons;
  additionalData?: LeadOptionalData;
}

function LeadForm(props: FormProps) {
  const [sent, setSent] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | undefined>();
  const [error, setError] = useState<AxiosError | undefined>();
  const store = useAppStore();
  const DEFAULT_SUCCESS_MESSAGE = "Děkujeme Vám za důvěru. Brzy se vám ozveme.";
  const DEFAULT_LOADING_MESSAGE = "Odesíláme váš požadavek...";
  const DEFAULT_ERROR_MESSAGE =
    "Ajéje, něco se pokazilo. Zkuste to prosím znovu později.";

  const successMessage = props.successMessage ?? DEFAULT_SUCCESS_MESSAGE;

  function handleSubmit(values: Partial<ContactDataNoPartner>) {
    if (props.handleSubmit) {
      props.handleSubmit(values as ContactDataNoPartner); // Validated
      return;
    }

    const leadData: LeadData = {
      reason: props.reason,
      tel: values.tel as string,
      ...props.additionalData,
    };

    setLoadingText(DEFAULT_LOADING_MESSAGE);
    axios
      .post("/send-lead", leadData)
      .then(() => {
        store.updateConfiguratorData(values);
        store.completeStep(3);

        trackLeadFormSent(props.reason === Reasons.Financing);
        setSent(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoadingText(undefined);
      });
  }

  return (
    <div className="p-6">
      <h3
        className={classNames("flex items-center space-x-4", {
          "mb-8": !sent,
        })}
      >
        <img src={props.icon} alt="House config" width={54} height="auto" />
        <span>
          {error ? DEFAULT_ERROR_MESSAGE : sent ? successMessage : props.title}
        </span>
      </h3>
      {!sent && !error && (
        <>
          <p className="text-lg font-bold mb-4">
            Nechte nám na sebe kontakt a my s vámi:
          </p>
          {props.stepsDesc?.length && (
            <ul className="list-disc space-y-2 pl-0 leading-5 ml-4 mb-8 text-lg font-normal">
              {props.stepsDesc.map((desc: string, i: number) => (
                <li key={`desc-${i}`}>{desc}</li>
              ))}
            </ul>
          )}
          <Formik
            initialValues={{
              partner: false,
              tel: DEFAULT_PHONE_NUMBER,
              agreement: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={leadFormValidationSchema}
          >
            <Form>
              <Input name="tel" type="tel" fullWidth placeholder="+420" />

              <Checkbox
                name="agreement"
                label={
                  <>
                    Potvrzuji, že jsem se seznámil/a se způsobem{" "}
                    <a
                      href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      zpracování osobních údajů
                    </a>
                  </>
                }
                className="mt-8 sm:mt-12"
              />
              <div
                className={
                  props.buttonType === "primary"
                    ? "md:text-left"
                    : "md:text-right"
                }
              >
                <Button
                  type={props.buttonType || "primary"}
                  label="Odeslat kontakt"
                  onClick={() => {}}
                  isSubmit
                  className="w-full md:w-auto"
                />
              </div>
            </Form>
          </Formik>
          <Loading delayed loadingText={loadingText} />
        </>
      )}
    </div>
  );
}

export default LeadForm;
