// import savingCoefficients from "shared/savingCoefficients";
import { formatNumber, round } from "./number";
import { Loan } from "./types";

// TODO: bude preneseno do CMS
export type SavingsCoefficientsType = Record<string, Record<string, number>>;

const uverKDotaci = {
  7: 1.65,
  8: 1.28,
  9: 1,
  10: 0.8,
  11: 0.64,
  12: 0.52,
  13: 0.43,
  14: 0.36,
  15: 0.33,
  16: 0.32,
  17: 0.285,
  18: 0.25,
  19: 0.22,
  20: 0.21,
  21: 0.205,
  22: 0.185,
  23: 0.17,
  24: 0.155,
  25: 0.15,
  26: 0.148,
  27: 0.141,
  28: 0.13,
  29: 0.122,
  30: 0.12,
};

const uverKDotaciPoBabicce = {
  7: 0.69,
  8: 0.66,
  9: 0.63,
  10: 0.6,
  11: 0.57,
  12: 0.54,
  13: 0.51,
  14: 0.48,
  15: 0.45,
  16: 0.414,
  17: 0.378,
  18: 0.342,
  19: 0.306,
  20: 0.27,
};

const savingCoefficients: SavingsCoefficientsType = {
  "uver-k-dotaci": uverKDotaci,
  "uver-k-dotaci-po-babicce": uverKDotaciPoBabicce,
};

export type LoanVariant = {
  pay: number;
  name: string;
  trackingName: string;
  interestInfo: string;
  pluses: string[];
  minuses: string[];
  link: string;
  disabled: boolean;
  nameCode: string;
  onlyWithBabicka: boolean;
};

export const computePay = (
  loanAmount: number,
  loanLength: number,
  interest: number,
) =>
  (loanAmount * (interest / (12 * 100))) /
  (1 - Math.pow(1 + interest / (12 * 100), -12 * loanLength));

/**
 * formula: celkovy úrok + dospoření
 */
export const computePayWithSavings = (
  loanAmount: number,
  loanLength: number,
  interest: number,
  codeName: string,
) => {
  const coefficient = savingCoefficients[codeName][loanLength];
  const interestToPay = ((loanAmount / 100) * interest) / 12;
  // TODO: anglicky vyraz pro `dospoření`?
  // vzorec: (částka/100)  x "koef. pro dospoření"
  const savings = (loanAmount / 100) * coefficient;

  return interestToPay + savings;
};

const computeInterest = (loan: Loan, loanAmount: number) => {
  let interest = loan.defaultInterest;
  loan.interestExceptions.forEach((exception) => {
    if (loanAmount <= exception.maxAmount) {
      interest = exception.interest;
    }
  });
  return interest;
};

const computeIsAvailable = (
  loan: Loan,
  loanAmount: number,
  loanLength: number,
) => {
  const minAmount = loan.minAmount ?? 0;
  const maxAmount = loan.maxAmount ?? Infinity;
  const minLength = loan.minLength ?? 0;
  const maxLength = loan.maxLength ?? Infinity;

  return (
    loanAmount >= minAmount &&
    loanAmount <= maxAmount &&
    loanLength >= minLength &&
    loanLength <= maxLength
  );
};

// Exception used in mortgage minuses
const replacePercentText = (arr: string[], loanAmount: number) => {
  return arr.map((minus) =>
    minus.replace(
      "125% půjčované částky",
      `${formatNumber(round(loanAmount * 1.25, -3), "Kč")}`,
    ),
  );
};

/**
 *  Regular loan computation
 */
const computeLoan = (
  loan: Loan,
  loanAmount: number,
  loanLength: number,
): LoanVariant => {
  const interest = computeInterest(loan, loanAmount);
  const interestFormatted = interest.toLocaleString("cs-CZ");
  const fixed = loan.interestFixed ? "pevná " : "";
  const from = loan.interestFrom ? "od " : "";

  return {
    pay: loan.withSavings
      ? computePayWithSavings(loanAmount, loanLength, interest, loan.nameCode)
      : computePay(loanAmount, loanLength, interest),
    name: loan.name,
    trackingName: loan.trackingName,
    interestInfo: `${fixed}úroková sazba ${from}${interestFormatted} % p.a.`,
    pluses: loan.pluses,
    minuses: replacePercentText(loan.minuses, loanAmount),
    link: loan.link,
    disabled: !computeIsAvailable(loan, loanAmount, loanLength),
    nameCode: loan.nameCode,
    onlyWithBabicka: loan.onlyWithBabicka,
  };
};

export const getLoanVariants = (
  loans: Loan[],
  loanAmount: number,
  loanLength: number,
): LoanVariant[] =>
  loans.map((loan) => computeLoan(loan, loanAmount, loanLength));


export const availableVariants = (
  loans: Loan[],
  loanAmount: number,
  loanLength: number,
): number =>
  loans.filter((loan) => computeIsAvailable(loan, loanAmount, loanLength))
    .length;

export const lowestPossiblePay = (
  loans: Loan[],
  loanAmount: number,
  loanLength: number,
): number => {
  const availableLoans = loans.filter((loan) =>
    computeIsAvailable(loan, loanAmount, loanLength),
  );

  if (availableLoans.length === 0) {
    return 0;
  }

  return availableLoans.reduce((prev, curr) => {
    const currPay = computePay(
      loanAmount,
      loanLength,
      computeInterest(curr, loanAmount),
    );
    return currPay < prev ? currPay : prev;
  }, Infinity);
};
