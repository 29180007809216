import FinalImage from "assets/final.png";

function ThankYouStep() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 sm:gap-4">
      <div className="flex flex-col justify-center">
        <h2 className="mb-8">
          Výsledky jsme poslali na váš e-mail. Brzy se těšíme na slyšenou
        </h2>
        <h3 className="mb-8">
          Mezitím si můžete vybrat spolehlivého dodavatele. Při financování s
          ČSOB získáte od našich partnerů zajímavé slevy.
        </h3>
        <h3>
          Něco vám v konfigurátoru chybělo?{" "}
          <a
            href="https://qdesigners.typeform.com/to/ViQVrYAS"
            target="_blank"
            rel="noreferrer"
            className="link-primary"
          >
            Napište nám!
          </a>
        </h3>
      </div>
      <div className="flex flex-col justify-center">
        <img src={FinalImage} alt="We are done!" />
      </div>
    </div>
  );
}

export default ThankYouStep;
