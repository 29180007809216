import Tooltip from "components/Tooltip";
import { formatNumber, round } from "shared/number";

interface SavingsResultProps {
  saving: number | null;
}

function SavingsResult(props: SavingsResultProps) {
  const { saving } = props;

  return (
    <div className="bg-darkblue text-white p-4 flex flex-col justify-between gap-6">
      <p className="text-base font-bold">
        Orientační budoucí úspora po komplexní rekonstrukci{" "}
        <Tooltip text="Výsledná úspora je dána rozdílem mezi vašimi současnými náklady (na vytápění domu a ohřev vody) a odhadovanými náklady po komplexní rekonstrukci." />
      </p>
      <p className="text-3xl font-bold md:font-normal">
        až{" "}
        {saving && saving > 499
          ? `${formatNumber(round(saving, 0), "Kč")} /měsíc`
          : "80%"}
      </p>
    </div>
  );
}

export default SavingsResult;
