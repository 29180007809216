import "./HomePage.scss";
import { useState } from "react";
import Step from "pages/home/components/Step";
import { defaultState, useAppStore } from "stores/appStore";
import { isNotProduction } from "helpers/environment";
import * as assetsObject from "./assets";
import Modal from "components/Modal/Modal";
import LeadForm from "components/form/LeadForm";
import IconPhone from "assets/icons/phone.svg";
import {
  Prelude,
  Step2DoneMessage,
  Step3DoneMessage,
} from "pages/home/components/Miscellaneous";
import { getHomeSteps } from "pages/home/helpers/getHomeSteps";
import CPD from "assets/cpd.png";
import { Reasons } from "shared/reasons";

function HomePage() {
  // Dumb state to force rerender
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCounter] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const assets = assetsObject as Record<string, any>; // TODO type
  const partner = !!new URLSearchParams(window.location.search).get("partner");
  const storeState = useAppStore.getState();
  const completedSteps = (partner ? defaultState : storeState).completedSteps;
  const followingStep = Object.entries(completedSteps).find(
    ([_, completed]) => !completed,
  )?.[0];

  const steps = getHomeSteps({
    openModal: () => setShowModal(true),
    completeStep: (step: number) => {
      storeState.completeStep(step);
      setCounter((prev) => prev + 1);
    },
  });

  const renderDoneMessage = function (step: number) {
    switch (step) {
      case 2:
        return <Step2DoneMessage assets={assets} />;
      case 3:
        return <Step3DoneMessage assets={assets} />;
      default:
        return null;
    }
  };

  return (
    <div className="HomePage">
      {showModal && (
        <Modal isOpened={showModal} onClose={() => setShowModal(false)}>
          <LeadForm
            buttonType="secondary"
            icon={IconPhone}
            title="Chcete probrat možnosti financování?"
            stepsDesc={[
              "Detailně projdeme varianty úvěrů",
              "Provedeme vás dalšími kroky a podáme informace k dotacím",
              "Doporučíme vám naše partnery na rekonstrukci",
            ]}
            reason={Reasons.Financing}
          />
        </Modal>
      )}

      <Prelude assets={assets} />
      <div>
        <div className="max-w-6xl m-auto px-4 py-6 lg:py-20">
          <h2 className="mb-6 font-medium md:font-normal text-2xl md:text-4xl">
            Jak na rekonstrukci krok za krokem
          </h2>
          <div className="space-y-6">
            {steps.map((step, i: number) => {
              const { button } = step;
              const currentStep = i + 1;
              const currentStepId = `Step${currentStep}`;
              const isComplete = completedSteps[currentStep];
              const icon = completedSteps[currentStep]
                ? assets[`${currentStepId}Completed`]
                : assets[currentStepId];

              // custom button settings
              if (button) {
                button.labelMobile = "";

                // whether to hide button when step is completed
                if ("hideButtonOnDone" in step) {
                  button.isVisible = !partner && isComplete === false;
                }
              }

              return (
                <Step
                  {...step}
                  button={button}
                  icon={icon}
                  key={`HopmePageStep-${currentStep}`}
                  active={!partner && followingStep === String(currentStep)}
                  transparent={
                    !partner &&
                    !isComplete &&
                    followingStep !== String(currentStep)
                  }
                >
                  {isComplete && renderDoneMessage(currentStep)}
                </Step>
              );
            })}

            {isNotProduction && (
              <Step
                button={{
                  label: "Smazat uložená data",
                  onClick: () => {
                    storeState.clearStore();
                    window.location.reload();
                  },
                  type: "primary",
                }}
                title="Začít znovu"
                icon={assetsObject.Step9}
                transparent
              >
                <div className="italic">Tato karta se na produkci neukáže</div>
              </Step>
            )}
          </div>
        </div>

        <div className="flex items-center gap-8 flex-col md:flex-row max-w-6xl md:m-auto mb-10 lg:mb-20 mx-4 px-4 py-10 pb-8 bg-white md:bg-transparent">
          <img src={CPD} alt="Centrum pasivního domu" width={236} height={77} />
          <div>
            <h5 className="mb-3.5">
              Konfigurátor jsme postavili ve spolupráci s Centrem pasivního domu
            </h5>
            <div>
              <a
                href="https://www.pasivnidomy.cz"
                className="link link-primary"
                target="_blank"
                rel="noreferrer"
              >
                Centrum pasivního domu
              </a>{" "}
              je nezávislé sdružení, v rámci kterého 100+ ověřených firem a
              specialistů navrhuje, staví nebo vyrábí technologické komponenty
              pro úsporné domy. A to už přes 15 let.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
