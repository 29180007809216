import { PartnerParamType } from "shared/types";

export enum RouteEnum {
  HomePage = "",
  Configurator = "konfigurator",
  Grant = "dotace",
  HowToApply = "jak-pozadat",
  NotFound = "*",
}

export const getLink = (route: RouteEnum) => {
  const { search } = window.location;
  if (route === RouteEnum.NotFound) {
    throw new Error("Cannot create link to not found page");
  }
  return `/${route}${search}`;
};

export const getPartnerParam = (): PartnerParamType => {
  const searchParams = new URLSearchParams(window.location.search);
  const partner = searchParams.get("partner");

  return !partner || partner === "false" ? false : partner;
};

export const getRoute = (path: string) => {
  const route = path.substring(1).split("?")[0];
  if (Object.values(RouteEnum).includes(route as RouteEnum)) {
    return route as RouteEnum;
  }
  return RouteEnum.NotFound;
};
