import Calculator from "assets/icons/calculator.svg";
import Input from "components/form/Input";
import { useFormikContext } from "formik";
import { getPartnerParam } from "helpers/routes";
import { getPartnerSalutation } from "shared/textations";
import { ValidatedFormData } from "shared/types";

function LeadFormCard() {
  const { values } = useFormikContext<ValidatedFormData>();
  const { partner } = values;

  return (
    <div className="bg-lightblue p-6">
      <h3 className="flex items-center space-x-4">
        <img src={Calculator} alt="Financing" />
        <span>
          {partner
            ? `Vyplňte údaje ${getPartnerSalutation(false)}`
            : "Získejte nabídku financování projektu"}
        </span>
      </h3>
      {partner ? (
        <>
          <Input
            label={`Jméno
              ${getPartnerSalutation(false)}
            `}
            name="partnerName"
            placeholder="Jan Novák"
            className="mt-8"
          />
          <Input
            label={`Telefonní číslo
              ${getPartnerSalutation(false)}
            `}
            name="partnerTel"
            type="tel"
            placeholder="+420"
            className="mt-8"
          />
          <Input
            label={`E-mail
              ${getPartnerSalutation(false)}
            `}
            name="partnerEmail"
            type="email"
            placeholder={`${
              getPartnerParam() === "csob"
                ? "specialista@csob.cz"
                : "obchodni-zastupce@csob.cz"
            }`}
            className="mt-8"
          />
        </>
      ) : (
        <>
          <div className="mt-8">
            <strong>Do 24 hodin:</strong>
            <ul className="list-disc space-y-2 pl-4 mt-4">
              <li>
                Provedeme vás dalšími kroky a podáme informace k dotacím NZÚ.
              </li>
              <li>Doporučíme vám naše partnery na rekonstrukci.</li>
              <li>Projdeme s vámi detailně možnosti financování.</li>
            </ul>
            <Input
              label="Na jaké číslo vám můžeme zavolat?"
              name="tel"
              type="tel"
              placeholder="+420"
              className="mt-8"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default LeadFormCard;
