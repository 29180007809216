export const yearLabel = (years?: number) => {
  if (years === undefined) {
    return undefined;
  }
  // eslint-disable-next-line
  if (years == 1) {
    return "rok";
  }
  if (years > 1 && years < 5) {
    return "roky";
  }
  return "let";
};
