import Spinner from "assets/loader.gif";
import classNames from "classnames";
import { useEffect, useState } from "react";
import "components/layout/Loading.scss";

const LOADING_DELAY = 500; // ms

type LoadingProps = {
  delayed?: boolean;
  loadingText?: string;
};

function Loading(props: LoadingProps) {
  const { loadingText, delayed } = props;
  const [showDelayed, setShowDelayed] = useState(false);
  const show = loadingText !== undefined;
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (delayed) {
      if (show) {
        const timeout = setTimeout(() => {
          setShowDelayed(true);
        }, LOADING_DELAY);
        return () => {
          clearTimeout(timeout);
        };
      } else {
        clearTimeout(timeout);
        setShowDelayed(false);
      }
    } else {
      setShowDelayed(show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div className={classNames("loading", { show: showDelayed })}>
      <img src={Spinner} alt="Loading" />
      <h4 className="mb-6 mt-8 text-darkblue">{props.loadingText}</h4>
      <span>Nezavírejte tuto stránku, aby se nahrávání nepřerušilo</span>
    </div>
  );
}

export default Loading;
