import { getGrantTableData } from "pages/grants/helpers/tableData";
import Table from "pages/grants/components/Table";
import classNames from "classnames";
import { useAppStore } from "stores/appStore";
import { GrantFormData } from "components/GrantTypeStep";
import { useFormikContext } from "formik";

function Step2() {
  const { values } = useFormikContext<GrantFormData>();
  const isBabickaEligible =
    values.economicallyActive && values.permanentResidence;
  const partner = !!new URLSearchParams(window.location.search).get("partner");
  const storeState = useAppStore.getState();
  const totalGrant = partner ? undefined : storeState.grantData?.totalGrant;
  const data = getGrantTableData(false, totalGrant);
  const mobileDataRaw = getGrantTableData(true, totalGrant);

  const sliceStart = isBabickaEligible ? 0 : 1;
  const mobileData = {
    ...mobileDataRaw,
    switch: isBabickaEligible,
    headCells: mobileDataRaw.headCells.slice(sliceStart, 2),
    rows: mobileDataRaw.rows.map((row) => ({
      cells: row.cells.slice(sliceStart, 2),
    })),
  };

  return (
    <>
      <h2 className="mb-6">
        {isBabickaEligible ? "Srovnání dotací" : "Souhrn dotace"}
      </h2>
      <h4 className="mb-14">
        Zohlednili jsme výsledky a vaše předchozí odpovědi.
      </h4>

      <Table
        data={data}
        // both choices available
        className={classNames("grant-table", "Table-desktop", {
          "grant--babicka": isBabickaEligible,
        })}
      >
        <colgroup>
          {isBabickaEligible ? (
            <>
              <col />
              <col width="30%" />
              <col width="30%" />
            </>
          ) : (
            <>
              <col width="55%" />
              <col width="45%" />
            </>
          )}
        </colgroup>
      </Table>

      <Table
        data={mobileData}
        className={classNames("grant-table", "Table-mobile", {
          "table-grant--babicka": isBabickaEligible,
        })}
      />
    </>
  );
}

export default Step2;
