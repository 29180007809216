export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const formatNumber = (value?: number, currency?: string) =>
  !value
    ? currency
      ? `0 ${currency}`
      : "0"
    : value.toLocaleString("cs-CZ", { maximumFractionDigits: 0 }) +
      (currency ? ` ${currency}` : "");

export const formatPhoneNumber = (value: string) =>
  value.replace(/(\+420)?(\d{3})(\d{3})(\d{3})/, "$2 $3 $4");
